import React, { useEffect, useState } from 'react';

import addImg from '../../assets/icons/AddImg.svg';

import { MdChevronLeft, MdClose, MdCheck } from 'react-icons/md';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { BiSolidChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { getAllHotels, getHotelManagerList } from '../../api/hotel';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { HiDocument } from 'react-icons/hi'
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi'
import { MdSearch } from 'react-icons/md'
import { TbFilterPlus } from 'react-icons/tb'
import { BiSolidShow } from "react-icons/bi";
import { BiSolidHide } from "react-icons/bi";

const nationalities = [
    { name: 'France', icon: '🇫🇷' },
    { name: 'UK', icon: '🇬🇧' },
    { name: 'Italy', icon: '🇮🇹' },
    { name: 'US', icon: '🇺🇸' },
    { name: 'German', icon: '🇩🇪' },
    { name: 'Spanish', icon: '🇪🇸' }
]

const locations = [
    { city: 'Cannes', country: 'France' },
    { city: 'Paris', country: 'France' },
    { city: 'Prague', country: 'Czech' }
];

export const Settings = ({ handleLogout }) => {
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);
    const [nationsOpen, setNationsOpen] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [locationOpen, setLocationsOpen] = useState(false);
    const [entityOpen, setEntityOpen] = useState(false);
    const [managersOpen, setManagersOpen] = useState(false);
    const [managers, setManagers] = useState([]);
    const [selectedPage, setSelectedPage] = useState("Personal");
    const [personal, setPersonal] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [passwordShown, setPasswordShown] = useState(false);

    const [member, setMember] = useState({
        firstname: '',
        lastname: '',
        position: '',
        nationality: [],
        roles: [],
        available: false,
        entity: [],
        locations: [],
        manager: [],
        email: '',
        phone: '',
        access: false,
        notifications: [
            {
                messages: false,
                mentions: false,
                hotelCreation: false,
                newMember: false,
                hotelStatus: false,
                ticketCreation: false,
                hotelierMessage: false
            }
        ]
    })

    const [document, setDocument] = useState({
        name: '',
        date: '',
        size: '',
        type: '',
        file: ''
    });

    const handlePage = (page) => {
        setSelectedPage(page)
    }

    const hiddenFileInput = React.useRef(null);
    const [documentInput, setDocumentInput] = useState(null);

    const handlePhoto = () => {
        hiddenFileInput.current.click();
    };

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    if (img.width >= 150 && img.height >= 150) {
                        setMember({ ...member, photo: e.target.result });
                    } else {
                        alert('Image size must be at least 150x150 pixels ')
                    }
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleDocument = (key) => {
        hiddenFileInput.current.click();
        setDocumentInput(key);
    };

    const handleInputDocuments = (event) => {

        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const documentData = e.target.result;

                switch (documentInput) {
                    case 'document':
                        setDocument({ ...document, name: event.target.files[0].name, date: event.target.files[0].lastModifiedDate.toDateString(), size: parseFloat(event.target.files[0].size / 1000000).toFixed(2), type: event.target.files[0].type, file: e.target.result });
                        break;
                    default:
                        return;
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleCheckbox = (type, value) => {
        const updatedMember = { ...member };

        updatedMember.notifications[0][type] = value;

        setMember(updatedMember);
    }

    const handleInputText = (e) => {
        setMember({ ...member, [e.target.name]: e.target.value });
    }

    const handleSelect = (key, value) => {
        setMember({ ...member, [key]: [value] });
    }

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    const handleSubmit = () => {
    }

    useEffect(() => {
        let token = (localStorage.getItem('auth_token'));
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem('token_exp'));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }
        }
    }, [handleLogout]);

    useEffect(() => {
        getAllHotels('', '', response => {
            if (response?.status === 'success') {
                setHotels(response?.content?.hotels);
            }
        })

        getHotelManagerList(response => {
            if (response?.status === 'success') {
                let m = response?.content
                let res = []
                m.map(el => {
                    el.manager.map(item => {
                        res.push(item)
                    })
                })
                setManagers(res)
            }
        })
    }, [])

    const renderPage = () => {
        switch (selectedPage) {
            case "Personal":
                return (
                    <form
                        className={`page-container`}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        {infoModal &&
                            <div
                                className='filter-darker'
                                onClick={() => {
                                    if (infoModal) {
                                        setInfoModal(false);
                                    }
                                }}
                            />
                        }

                        <div className='subtitle mt10'>General information*</div>
                        <div className='border-container r8 mt20'>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>First name*</div>
                                <div className='row-view flex1 items-center'>
                                    <input className='input-text w300 ph20' name='firstname' placeholder='First name...' value={member?.firstname} onChange={(e) => handleInputText(e)} />
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Last name*</div>
                                <div className='row-view flex1 items-center'>
                                    <input className='input-text w300 ph20' name='lastname' placeholder='Last name...' value={member?.lastname} onChange={(e) => handleInputText(e)} />
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Position*</div>
                                <div className='row-view flex1 items-center'>
                                    <input className='input-text w300 ph20' name='lastname' placeholder='Position...' value={member?.position} onChange={(e) => handleInputText(e)} />
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Photo*</div>
                                <div className='row-view flex1 items-center'>
                                    <div className='row-view items-center'>
                                        {!member?.photo ?
                                            <div
                                                className='border-container b-dashed b2 r8 p20 mr20'
                                                onClick={(e) => handlePhoto(e)}
                                            >
                                                <img
                                                    src={addImg}
                                                    alt='addImg'
                                                />
                                            </div>
                                            :
                                            <div
                                                className='p20 mr20 relative'
                                                onClick={(e) => handlePhoto(e)}
                                            >
                                                <img
                                                    className='r8'
                                                    src={member?.photo}
                                                    alt="staffPhoto"
                                                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                                />
                                                <div
                                                    className='close-icon'
                                                    onClick={() => setMember({ ...member, photo: null })}
                                                >
                                                    <MdClose size={20} color='white' />
                                                </div>
                                            </div>
                                        }
                                        <div className='section-subtitle'>(JPGs or PNGs of at least 150x150px)</div>
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        ref={hiddenFileInput}
                                        onChange={(e) => handleInputPhoto(e)}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Availability*</div>
                                <div className='row-view flex1 items-center'>
                                    <div className='row-view items-center w300'>
                                        <div className={`toggle mr10 ${member?.available && 'checked'}`} onClick={() => setMember({ ...member, available: !member?.available })}>
                                            <div className={`toggle-span ${member?.available && 'checked'}`} />
                                        </div>
                                        <div className='section-title mr10'>Available</div>
                                        <div className='relative row-view items-center'>
                                            <BsFillQuestionCircleFill color='#428C78' size={20} onClick={() => setInfoModal(!infoModal)} />
                                            <div className={`info-modal ${infoModal && 'active'}`}>
                                                Is this hotelier unavailable or on vacation? Update its status to <span className='green-text bold'>"not available"</span> to notify every member. The hotelier will be <span className='green-text bold'>hidden in the application</span> during this period.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Entity linked*</div>
                                <div className='row-view flex1 items-center'>
                                    <div className='relative'>
                                        <div
                                            className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20'
                                            onClick={() => setEntityOpen(!entityOpen)}
                                        >
                                            <div>{member?.entity?.length ? `${member?.entity[0].name} - ${member?.entity[0].city}` : 'Select hotel'}</div>
                                            <BiSolidChevronDown />
                                        </div>
                                        <div className={`select-menu ${entityOpen && 'open'}`}>
                                            {hotels?.length !== 0 ?
                                                <>
                                                    {hotels.map((hotel, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className='select-item'
                                                                onClick={() => {
                                                                    handleSelect('entity', hotel);
                                                                    setEntityOpen(false);
                                                                }}
                                                            >
                                                                <span className='section-title'>{hotel.name}</span> - {hotel.city}
                                                            </div>
                                                        )
                                                    })}</>
                                                :
                                                <>
                                                    <div
                                                        className='select-item'
                                                    >
                                                        No hotels found
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Favourite locations</div>
                                <div className='row-view flex1 items-center'>
                                    <div className='relative'>
                                        <div
                                            className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20'
                                            onClick={() => setLocationsOpen(!locationOpen)}
                                        >
                                            <div>{member?.locations?.length !== 0 ? member?.locations[0].city + ' - ' + member?.locations[0].country : 'Select destinations'}</div>
                                            <BiSolidChevronDown />
                                        </div>
                                        <div className={`select-menu ${locationOpen && 'open'}`}>
                                            {locations?.length !== 0 ?
                                                <>
                                                    {locations.map((location, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className='select-item'
                                                                onClick={() => {
                                                                    handleSelect('locations', location);
                                                                    setLocationsOpen(false);
                                                                }}
                                                            >
                                                                <span className='section-title'>{location.city}</span> - {location.country}
                                                            </div>
                                                        )
                                                    })}</>
                                                :
                                                <>
                                                    <div
                                                        className='select-item'
                                                    >
                                                        No locations found
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Email address*</div>
                                <div className='row-view flex1 items-center'>
                                    <input className='input-text w300 ph20' name='email' placeholder='Email address...' value={member?.email} onChange={(e) => handleInputText(e)} />
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Phone number*</div>
                                <div className='row-view flex1 items-center'>
                                    <input className='input-text w300 ph20' name='phone' placeholder='Phone number...' value={member?.phone} onChange={(e) => handleInputText(e)} />
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Manager*</div>
                                <div className='row-view flex1 items-center'>
                                    <div className='relative'>
                                        <div
                                            className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                            onClick={() => setManagersOpen(!managersOpen)}
                                        >
                                            <div>{member?.manager?.length !== 0 ? `${member?.manager[0].firstname} ${member?.manager[0].lastname}` : 'Select Manager'}</div>
                                            <BiSolidChevronDown />
                                        </div>
                                        <div className={`select-menu ${managersOpen && 'open'}`}>
                                            {managers?.length !== 0 ?
                                                <>
                                                    {managers.map((manager, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className='select-item'
                                                                onClick={() => {
                                                                    handleSelect('manager', manager);
                                                                    setManagersOpen(false);
                                                                }}
                                                            >
                                                                <span className='section-title'>{manager.firstname} {manager.lastname}</span>
                                                            </div>
                                                        )
                                                    })}</>
                                                :
                                                <>
                                                    <div
                                                        className='select-item'
                                                    >
                                                        No managers found
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Contract*</div>

                                <div className='column-view flex1'>
                                    <div className={`bgFAFAFA row-view items-center w80p p20 b r8 cursor-pointer`}>
                                        <div className="row-column items-center justify-center w80p">
                                            <div className="row-view justify-center section-subtitle mb10">
                                                PDF, JPG, PNG are allowed
                                            </div>

                                            <div
                                                className="border-container b-dashed b2 r8 p20 cursor-pointer mr10 bgLightGreen column-view justify-center items-center"
                                                onClick={() => handleDocument('document')}
                                            >
                                                <AiOutlineCloudUpload size={60} color='#428C78' />
                                                <div className='green-text bold'>Browse to choose a file</div>
                                            </div>
                                            {document?.file ?
                                                (
                                                    <div className='row-view justify-between border-container w100 pv12 r8 mb20 mt20'>
                                                        <div className='row-view'>
                                                            <HiDocument className='ml10' size={50} color='#428C78' />
                                                            <div className='column-view justify-center '>
                                                                <div className='bold'>{document.name}</div>
                                                                <div className='color-grey mt05'>{document.date + ' - ' + document.size} Mo</div>
                                                            </div>
                                                        </div>
                                                        <div className='cursor-pointer'>
                                                            <PiDotsThreeOutlineVerticalFill size={50} color='#428C78' />
                                                        </div>
                                                    </div>
                                                ) : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='subtitle mt50'>Password*</div>
                        <div className='border-container r8 mt20'>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Current password*</div>
                                <div className='row-view flex1 items-center'>
                                    <input className='input-text w300 ph20' name='firstname' placeholder='First name...' value='●●●●●●●●●●' disabled='true' />
                                </div>
                            </div>
                            <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>New password*</div>
                                <div className="password-input-container">
                                    <input className='input-text w300 ph20' type={passwordShown ? "text" : "password"} name='confnewpassword' placeholder='Confirm new password...' onChange={(e) => handleInputText(e)} />
                                    {passwordShown ? <BiSolidHide size={25} className="show-password-button mr20" onClick={togglePassword} /> : <BiSolidShow size={25} className="show-password-button mr20" onClick={togglePassword} />}
                                </div>
                            </div>
                            <div className='row-view items-center p20'>
                                <div className='section-title flex04'>Confirm new password*</div>

                                <div className='row-view flex1'>
                                    <div className="password-input-container">
                                        <input className='input-text w300 ph20' type={passwordShown ? "text" : "password"} name='confnewpassword' placeholder='Confirm new password...' onChange={(e) => handleInputText(e)} />
                                        {passwordShown ? <BiSolidHide size={25} className="show-password-button mr20" onClick={togglePassword} /> : <BiSolidShow size={25} className="show-password-button mr20" onClick={togglePassword} />}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row-view justify-end mt20'>
                            <button className='secondary-button mr20' type='reset' onClick={() => navigate(-1)}>Cancel</button>
                            <button className='primary-button' type='submit'>Create Contact</button>
                        </div>
                        <input
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputDocuments(e)}
                            style={{ display: 'none' }}
                        />
                    </form>

                )
            case "Notifications":
                return (
                    <div>
                        <div>
                            <div className='title-secondary green-text ml50 mt20'>Notifications</div>
                            <div className='column-view justify-center ml50'>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('messages', !member.notifications[0].messages)}>
                                    <div className={`checkbox r4 mr10 ${member?.notifications && member?.notifications[0].messages === true && 'selected'}`} >
                                        {member?.notifications[0].messages === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Messages notifications</div>
                                        <div>Receive a notification when you receive a message.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('mentions', !member.notifications[0].mentions)}>
                                    <div className={`checkbox r4 mr10 ${member?.notifications && member?.notifications[0].mentions === true && 'selected'}`}  >
                                        {member?.notifications[0].mentions === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Mentions</div>
                                        <div>Be informed as soon as you are mentioned.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('hotelCreation', !member.notifications[0].hotelCreation)}>
                                    <div className={`checkbox r4 mr10 ${member?.notifications && member?.notifications[0].hotelCreation === true && 'selected'}`}  >
                                        {member?.notifications[0].hotelCreation === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Hotels creation</div>
                                        <div>Receive a notification when a hotel is created. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='title-secondary green-text ml50 mt50'>Email</div>
                            <div className='column-view justify-center ml50'>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('newMember', !member.notifications[0].newMember)}>
                                    <div className={`checkbox r4 mr10 ${member?.notifications && member?.notifications[0].newMember === true && 'selected'}`} >
                                        {member?.notifications[0].newMember === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>New member</div>
                                        <div>Receive an email when a member is created.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('hotelStatus', !member.notifications[0].hotelStatus)}>
                                    <div className={`checkbox r4 mr10 ${member?.notifications && member?.notifications[0].hotelStatus === true && 'selected'}`} >
                                        {member?.notifications[0].hotelStatus === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Hotels status</div>
                                        <div>Receive an email when a hotel changes status.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('ticketCreation', !member.notifications[0].ticketCreation)}>
                                    <div className={`checkbox r4 mr10 ${member?.notifications && member?.notifications[0].ticketCreation === true && 'selected'}`} >
                                        {member?.notifications[0].ticketCreation === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Ticket creation</div>
                                        <div>Receive an email when a ticket is created.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('hotelierMessages', !member.notifications[0].hotelierMessages)}>
                                    <div className={`checkbox r4 mr10 ${member?.notifications && member?.notifications[0].hotelierMessages === true && 'selected'}`} >
                                        {member?.notifications[0].hotelierMessages === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Hoteliers messages</div>
                                        <div>Receive an email when you receive a message by hoteliers.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="page-container">
            <div className="row-view justify-between">
                <div className='row-view items-center'>
                    <MdChevronLeft
                        size={50}
                        onClick={() => navigate(-1)}
                    />
                    <div className='title-secondary'>Settings</div>
                </div>

                <div className="row-view">
                    <button className="secondary-button borderless row-view items-center">
                        <div className="mr5">
                            <TbFilterPlus />
                        </div>
                        <div>Filter</div>
                    </button>
                    <div className="secondary-search-bar-container row-view items-center justify-center">
                        <div className="mr10">
                            <MdSearch size={20} />
                        </div>
                        <input
                            type="text"
                            placeholder="Search reservations"
                            className="secondary-search-bar"
                        />
                    </div>
                </div>
            </div>

            <div className="row-view pb20 mt20 ml20">
                <div
                    className={`row-view items-center mr50 cursor-pointer pv4 ${selectedPage === 'Personal' && 'black-text bold bb161616'}`}
                    onClick={() => handlePage('Personal')}
                >
                    <div className={`mr5`}>
                        Personal
                    </div>
                    {personal.length !== 0 && <div className="notif">{personal.length}</div>}
                </div>
                <div className={`row-view items-center mr50 cursor-pointer pv4 ${selectedPage === 'Notifications' && 'black-text bold bb161616'}`}
                    onClick={() => handlePage('Notifications')}
                >
                    <div className={`mr5`}>
                        Notifications
                    </div>
                    {notifications.length !== 0 && <div className="notif">{notifications.length}</div>}
                </div>
            </div>


            <div className='of-scroll'>
                {renderPage()}
            </div>

        </div>)
}