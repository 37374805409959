import { API_URL } from "./const";

const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth_token'));
}

export const createStaff = async (staff, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/staff`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "POST",
        body: JSON.stringify(staff)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getAllStaff = async (callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/staff`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateStaffContent = async (content, sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/staff/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "PATCH",
        body: JSON.stringify(content)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            callback(response.ok);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const deleteStaffContent = async (sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/staff/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "DELETE"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getStaffContent = async (sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/staff/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};