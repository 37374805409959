import { API_URL } from "./const";

const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth_token'));
}

export const createHotel = async (hotel, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "POST",
        body: JSON.stringify(hotel)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getAllHotels = async (offset, limit, search, callback) => {
    const authToken = await getToken();
    let url = `${process.env.REACT_APP_API_URL}/hotel`
    if ((offset !== undefined && offset !== '') || (limit !== undefined && limit !== '')) {
        url += '?'
        if (offset !== undefined && offset !== '') {
            url += `offset=${offset}`
        }
        if ((offset === undefined || offset === '') && (limit === undefined && limit === '')) {
            url += `?limit=${limit}`
        } else {
            url += `&limit=${limit}`
        }
    }
    url += `&search=${search}`
    fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const searchHotels = async (offset, limit, search, callback) => {
    const authToken = await getToken();
    let url = `${process.env.REACT_APP_API_URL}/hotel/search?offset=${offset}&limit=${limit}&search=${search}`
    fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateHotelContent = async (content, sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "PATCH",
        body: JSON.stringify(content)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            callback(response.ok);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const deleteHotel = async (sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "DELETE"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelContent = async (sub, role, callback) => {
    const authToken = await getToken();
    // TODO: change to user role
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${sub}?role=${role}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const replaceHotelContent = async (content, sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "PUT",
        body: JSON.stringify(content)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelManagerList = async (callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/manager`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelOwnerList = async (callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/owner`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};


export const addHotelManager = async (hotel_sub, manager_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${hotel_sub}/manager/${manager_sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "PUT",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const removeHotelManager = async (hotel_sub, owner_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${hotel_sub}/manager/${owner_sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "DELETE",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};


export const addHotelOwner = async (hotel_sub, owner_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${hotel_sub}/owner/${owner_sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "PUT",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};


export const removeHotelOwner = async (hotel_sub, owner_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${hotel_sub}/owner/${owner_sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "DELETE",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};


export const addHotelStaff = async (hotel_sub, staff_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${hotel_sub}/staff/${staff_sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "PUT",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};


export const removeHotelStaff = async (hotel_sub, staff_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/${hotel_sub}/staff/${staff_sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "DELETE",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};
