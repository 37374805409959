import React, { useEffect, useState } from 'react';

import addImg from '../../assets/icons/AddImg.svg';

import { MdChevronLeft, MdClose } from 'react-icons/md';
import { BiSolidChevronDown } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getHotelContent, updateHotelContent } from '../../api/hotel';
import { getHigherRole } from '../../utils/utils';

const labels = [
    'Green Globe',
    'Green Tourism',
    'Earth Check'
]

const requirements = [
    { name: 'Parking', icon: '🚘' },
    { name: 'Balcony', icon: '🪟' },
    { name: 'View', icon: '🌄' },
    { name: 'Silent room', icon: '🛌' },
    { name: 'Smoking area', icon: '🔥' },
    { name: 'Ground floor', icon: '🪜' }
]

const foodHealth = [
    { name: 'Diabetic', icon: '💉' },
    { name: 'Vegan', icon: '🌱' },
    { name: 'ICD', icon: '🧰' },
    { name: 'Gluten free', icon: '🌿' },
    { name: 'Bug prevention', icon: '🪷' },
    { name: 'Pork free', icon: '🐷' },
    { name: 'Recent mattress', icon: '🛏️' },
    { name: 'High-end mattress', icon: '🛌' },
    { name: 'Seafood allergies', icon: '🦐' },
]

const communities = [
    { name: 'LGBTQ+', icon: '🏳️‍🌈' },
    { name: 'Cyclist', icon: '🚴‍♂️' },
    { name: 'Biker', icon: '🛵' },
    { name: 'Ecologist', icon: '🍀' },
    { name: 'Pet lover', icon: '🐶' },
    { name: 'Pork free', icon: '🐷' },
    { name: 'Recent Feminism', icon: '🌸' },
    { name: 'Cigar smokers', icon: '🛌' },
]

const musicList = [
    { title: 'Beat It', artist: 'Michael Jackson' },
    { title: 'September', artist: 'Earth, Wind & Fire' }
];

export const HotelEdit = ({ handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [hotelSub, setHotelSub] = useState(location.pathname.replace('/hotels/profile/', ''));
    const [infoModal, setInfoModal] = useState(false);
    const [labelsOpen, setLabelsOpen] = useState(false);
    const [musicOpen, setMusicOpen] = useState(false);
    const [myRoles] = useState(JSON.parse(localStorage.getItem('my_roles')))

    const [hotel, setHotel] = useState({
        name: '',
        showcase: {
            logo: null,
            banner: null,
        },
        address: '',
        zipcode: '',
        city: '',
        phone: '',
        presentation: '',
        overview: [null, null, null, null, null, null],
        year_of_creation: '',
        year_of_renovation: '',
        nb_rooms: '',
        labels: [],
        food_health: [],
        requirements: [],
        music_list: [],
        communities: []
    })

    const hiddenFileInput = React.useRef(null);
    const [photoInput, setPhotoInput] = useState(null);

    console.log(hotel)

    const handlePhoto = (key) => {
        hiddenFileInput.current.click();
        setPhotoInput(key);
    };

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    switch (photoInput) {
                        case 'logo':
                            if (img.width >= 150 && img.height >= 150) {
                                setHotel({ ...hotel, showcase: { ...hotel.showcase, logo: e.target.result } });
                            } else {
                                alert('Image size must be at least 150x150 pixels ')
                            }
                            break;
                        case 'banner':
                            if (img.width >= 1080 && img.height >= 566) {
                                setHotel({ ...hotel, showcase: { ...hotel.showcase, banner: e.target.result } });
                            } else {
                                alert('Image size must be at least 1080x566 pixels ')
                            }
                            break;
                        case 'overview':
                            if (img.width >= 150 && img.height >= 150) {
                                let inserted = false;
                                let idx = 0;
                                let arr = hotel.overview;
                                while (!inserted && idx < arr.length) {
                                    if (!arr[idx]) {
                                        arr[idx] = e.target.result;
                                        inserted = true;
                                    }
                                    idx++;
                                }
                                setHotel({ ...hotel, overview: [...hotel.overview] });
                            } else {
                                alert('Image size must be at least 1080x566 pixels ')
                            }
                            break;
                        default:
                            return 0;
                    }
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleInputText = (e) => {
        setHotel({ ...hotel, [e.target.name]: e.target.value });
    }

    const handleSelect = (key, value) => {
        setHotel({ ...hotel, [key]: [value] });
    }

    const handleTag = (key, value) => {
        if (hotel[key].includes(value)) {
            setHotel({ ...hotel, [key]: hotel[key].filter(el => el !== value) });
        } else {
            setHotel({ ...hotel, [key]: [...hotel[key], value] });
        }
    }

    const handleSubmit = () => {
        updateHotelContent(hotel, hotelSub, ok => {
            if (ok) {
                navigate(-1);
            }
        })
    }

    useEffect(() => {
        let token = (localStorage.getItem('auth_token'));
        let role = getHigherRole(myRoles)

        getHotelContent(hotelSub, role, response => {
            if (response.status === 'success') {
                setHotel({ ...hotel, ...response.content })
            }
        })
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem('token_exp'));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }
        }
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLogout]);

    return (
        <form
            className='page-container'
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            {infoModal &&
                <div
                    className='filter-darker'
                    onClick={() => {
                        if (infoModal) {
                            setInfoModal(false);
                        }
                    }}
                />
            }
            <div className="subtitle mt50 ml50">General information*</div>
            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Hotel name*</div>
                    <div className='row-view flex1 items-center'>
                        <input
                            className="input-text flex1 ph20"
                            name="name"
                            placeholder="Hotel name..."
                            value={hotel?.name}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Showcase*</div>
                    <div className={`flex1 ${windowSize > 1200 && 'row-view'}`}>
                        <div className='row-view items-center justify-between flex1'>
                            <div className="subtitle mr20">Logo</div>
                            <div className="row-view items-center">
                                <div className="section-subtitle mr20">
                                    (JPGs or PNGs of at least 150x150px)
                                </div>
                                {!hotel?.showcase?.logo ? (
                                    <div
                                        className="border-container b-dashed b2 r8 p20 cursor-pointer mr10"
                                        onClick={() => handlePhoto('logo')}
                                    >
                                        <img src={addImg} alt="addImg" />
                                    </div>
                                ) : (
                                    <div className="p20 mr20 relative mr10">
                                        <img
                                            className="r8"
                                            src={hotel?.showcase?.logo}
                                            alt="hotelPhoto"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <div
                                            className="close-icon"
                                            onClick={() =>
                                                setHotel({
                                                    ...hotel,
                                                    showcase: {
                                                        ...hotel?.showcase,
                                                        logo: null,
                                                    },
                                                })
                                            }
                                        >
                                            <MdClose size={20} color="white" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row-view items-center justify-between flex1'>
                            <div className="subtitle mr20">Banner</div>
                            <div className="row-view items-center">
                                <div className="section-subtitle mr20">
                                    (JPGs or PNGs of at least 1080x566 px)
                                </div>
                                {!hotel?.showcase?.banner ? (
                                    <div
                                        className="border-container b-dashed b2 r8 p20 cursor-pointer mr10"
                                        onClick={() => handlePhoto('banner')}
                                    >
                                        <img src={addImg} alt="addImg" />
                                    </div>
                                ) : (
                                    <div className="p20 mr20 relative mr10">
                                        <img
                                            className="r8"
                                            src={hotel?.showcase?.banner}
                                            alt="hotelPhoto"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <div
                                            className="close-icon"
                                            onClick={() =>
                                                setHotel({
                                                    ...hotel,
                                                    showcase: {
                                                        ...hotel?.showcase,
                                                        banner: null,
                                                    },
                                                })
                                            }
                                        >
                                            <MdClose size={20} color="white" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Address*</div>
                    <div className='row-view flex1 justify-between'>
                        <input
                            className="input-text flex05 ph20"
                            name="address"
                            placeholder="Address..."
                            value={hotel?.address.line1}
                            onChange={(e) => handleInputText(e)}
                        />
                        <input
                            className="input-text flex02 ph20"
                            name="zipcode"
                            placeholder="Zip code..."
                            value={hotel?.address.zip_code}
                            onChange={(e) => handleInputText(e)}
                        />
                        <input
                            className="input-text flex02 ph20"
                            name="city"
                            placeholder="City..."
                            value={hotel?.address.city}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Phone number*</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text flex1 ph20"
                        name="phone"
                        placeholder="Phone number..."
                        value={hotel?.phone[0]?.phone}
                        onChange={(e) => handleInputText(e)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Presentation*</div>
                    <div className="row-view flex1">
                        <textarea
                            className='input-text flex1'
                            name='presentation'
                            placeholder='Presentation...'
                            rows={8}
                            value={hotel?.presentation}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20">
                    <div className="section-title flex04">Hotel Overview*</div>
                    <div className="row-view flex1">
                        <div className="row-view items-center flex1">
                            <div className="subtitle mr20">Pictures</div>
                            <div className="row-view items-center">
                                <div className="section-subtitle mr20">
                                    (JPGs or PNGs of at least 150x150px)
                                </div>
                                <div className='grid-view3'>
                                    {hotel?.overview?.map((item, index) => {
                                        if (!item) {
                                            return (
                                                <div
                                                    className="border-container b-dashed b2 r8 p20 cursor-pointer"
                                                    onClick={() =>
                                                        handlePhoto('overview')
                                                    }
                                                    key={index}
                                                >
                                                    <img
                                                        src={addImg}
                                                        alt="addImg"
                                                    />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div
                                                    className="p20 mr20 relative"
                                                    key={index}
                                                >
                                                    <img
                                                        className="r8"
                                                        src={item}
                                                        alt="hotelPhoto"
                                                        style={{
                                                            width: '60px',
                                                            height: '60px',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                    <div
                                                        className="close-icon"
                                                        onClick={() => {
                                                            let arr = hotel?.overview
                                                            arr[index] = null
                                                            setHotel({
                                                                ...hotel,
                                                                overview: arr,
                                                            })
                                                        }}
                                                    >
                                                        <MdClose
                                                            size={20}
                                                            color="white"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="subtitle mt50 ml50">Hotel Specifications</div>
            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Year of creation</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text w300 ph20"
                        name="year_of_creation"
                        placeholder="Year of creation..."
                        value={hotel?.year_of_creation}
                        onChange={(e) => handleInputText(e)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Year of renovation</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text w300 ph20"
                        name="year_of_renovation"
                        placeholder="Year of renovation..."
                        value={hotel?.year_of_renovation}
                        onChange={(e) => handleInputText(e)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Number of rooms</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text w300 ph20"
                        name="nb_rooms"
                        placeholder="Number of rooms..."
                        value={hotel?.nb_rooms}
                        onChange={(e) => handleInputText(e)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Website</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text w300 ph20"
                        name="website"
                        placeholder="Website..."
                        value={hotel?.website}
                        onChange={(e) => handleInputText(e)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Our labels*</div>
                    <div className='row-view flex1 items-center'>
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer"
                                onClick={() => setLabelsOpen(!labelsOpen)}
                            >
                                <div>
                                    {hotel?.labels && hotel?.labels?.length !== 0
                                        ? hotel?.labels[0]
                                        : 'Select labels'}
                                </div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${labelsOpen && 'open'}`}>
                                {labels?.length !== 0 ? (
                                    <>
                                        {labels.map((label, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect(
                                                            'labels',
                                                            label
                                                        )
                                                        setLabelsOpen(false)
                                                    }}
                                                >
                                                    <span className="section-title">
                                                        {label}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">
                                            No labels found
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">
                        Food and health specificities
                    </div>
                    <div className="row-view wrap flex1">
                        {foodHealth.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${hotel?.food_health?.includes(item.name) &&
                                        'selected'
                                        }`}
                                    onClick={() =>
                                        handleTag('food_health', item.name)
                                    }
                                >
                                    <div className="mr10">{item.icon}</div>
                                    <div>{item.name}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">
                        Requirements covered
                    </div>
                    <div className="row-view wrap flex1">
                        {requirements?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${hotel?.requirements?.includes(item.name) &&
                                        'selected'
                                        }`}
                                    onClick={() =>
                                        handleTag('requirements', item.name)
                                    }
                                >
                                    <div className="mr10">{item.icon}</div>
                                    <div>{item.name}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">
                        Music brand identity
                    </div>
                    <div className='row-view flex1 items-center'>
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer"
                                onClick={() => setMusicOpen(!musicOpen)}
                            >
                                <div>
                                    {hotel?.music_list && hotel?.music_list?.length !== 0
                                        ? hotel?.music_list[0]?.title +
                                        ' - ' +
                                        hotel?.music_list[0]?.artist
                                        : 'Select music'}
                                </div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${musicOpen && 'open'}`}>
                                {musicList?.length !== 0 ? (
                                    <>
                                        {musicList?.map((music, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect(
                                                            'music_list',
                                                            music
                                                        )
                                                        setMusicOpen(false)
                                                    }}
                                                >
                                                    <span className="section-title">
                                                        {music.title}
                                                    </span>{' '}
                                                    - {music.artist}
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">
                                            No music found
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20">
                    <div className="section-title flex04">
                        Favorite communities
                    </div>
                    <div className="row-view wrap flex1">
                        {communities.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${hotel?.communities?.includes(item.name) &&
                                        'selected'
                                        }`}
                                    onClick={() =>
                                        handleTag('communities', item.name)
                                    }
                                >
                                    <div className="mr10">{item.icon}</div>
                                    <div>{item.name}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <input
                type="file"
                accept="image/png, image/jpeg"
                ref={hiddenFileInput}
                onChange={(e) => handleInputPhoto(e)}
                style={{ display: 'none' }}
            />
            <div className="row-view justify-end mt20">
                <button
                    className="secondary-button mr20"
                    type='button'
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </button>
                <button className="primary-button" type="submit">
                    Update
                </button>
            </div>
        </form>
    )
}