import React from 'react';
import { PiUserRectangleLight } from 'react-icons/pi'
import { Link } from 'react-router-dom'

export const TicketCard = ({ ticket }) => {
    return (
        <div className='row-view justify-between flex1 border-container r8 p20 mb20 bgFAFAFA'>
            <div className='column-view flex03 justify-between'>
                <div>
                    <div className='subtitle black-text'>{ticket.title}</div>
                    <div className='section-subtitle'>By {ticket.author}</div>
                </div>
                <div className='row-view'>
                    <div
                        className={`p10 r8 bold 
                        ${ticket.severity === 'High' ?
                                'red-text redbg01'
                                :
                                ticket.severity === 'Medium' ?
                                    'orange-text orangebg01'
                                    :
                                    ticket.severity === 'Low' &&
                                    'blue-text bluebg01'
                            }
                    `}
                    >
                        {ticket.severity}
                    </div>
                </div>
            </div>
            <div className='v-separator bg161616' />
            <div className='column-view flex06 justify-between'>
                <div className='paragraph'>{ticket.comment}</div>
                <div className='row-view justify-between items-center mt50'>
                    <div className='date'>Posted {ticket.date}</div>
                    <div className='row-view items-center'>
                        <button className='row-view items-center secondary-button borderless'>
                            <PiUserRectangleLight size={25} className='mr5' color='#428C78' />
                            <div className='green-text'>Share with my staff</div>
                        </button>
                        <Link
                            to={{ pathname: `/tickets/edit/${ticket.id}` }}
                            className='primary-button none-decoration-link h100'>
                            Edit
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}