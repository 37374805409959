import React from 'react';
import { HiDocument } from 'react-icons/hi'

export const DocumentCard = ({ document }) => {
    return (
        <div className='row-view w100 bgFAFAFA p10 r8 mb20'>
            <HiDocument size={50} color='#428C78' />
            <div className='column-view justify-center '>
                <div className='bold'>The mozart Hotel - Bill.pdf</div>
                <div className='color-grey mt05'>Feb 3. 2023 - 2.1 MB</div>
            </div>
        </div>
    )
}