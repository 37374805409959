import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

export const Messages = ({ messages, mySub }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef?.current?.scrollIntoView();
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [messages]);

  return (
    <div className="">
      {messages?.map((message, index) => {
        return (
          <div
            key={index}
            className={`row-view ph20 ${
              message?.sender_sub === mySub && "justify-end"
            }`}
          >
            {message?.sender_sub !== mySub ? (
              <div
                style={{
                  maxWidth: windowSize < 1200 ? "80%" : "60%",
                  lineHeight: 1.4,
                  letterSpacing: 1.2,
                  marginBottom: 10,
                }}
              >
                {(moment(message?.timestamp).diff(
                  moment(messages[index - 1]?.timestamp),
                  "minutes"
                ) > 15 ||
                  moment(message?.timestamp).diff(
                    moment(messages[index - 1]?.timestamp),
                    "minutes"
                  ) < 0) && (
                  <div className="grey-text mt20">
                    {moment(message?.timestamp).format("LT")}
                  </div>
                )}
                <div className="bgE9E9E9 p20 r8 mt5">{message?.content}</div>
              </div>
            ) : (
              <div
                style={{
                  maxWidth: windowSize < 1200 ? "80%" : "60%",
                  lineHeight: 1.4,
                  letterSpacing: 1.2,
                  marginBottom: 10,
                }}
              >
                {(moment(message?.timestamp).diff(
                  moment(messages[index - 1]?.timestamp),
                  "minutes"
                ) > 15 ||
                  moment(message?.timestamp).diff(
                    moment(messages[index - 1]?.timestamp),
                    "minutes"
                  ) < 0) && (
                  <div className="grey-text mt20">
                    {moment(message?.timestamp).format("LT")}
                  </div>
                )}
                <div className="bg428C78 white-text p20 r8 mt5">
                  {message?.content}
                </div>
                {index === messages.length - 1 && (
                  <div className="grey-text text-end mt5">{message?.state}</div>
                )}
              </div>
            )}
          </div>
        );
      })}
      <div ref={bottomRef} />
    </div>
  );
};
