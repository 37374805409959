import img from '../../assets/logos/image.svg';

export const getMembers = [
    {
        id: 1,
        img: img,
        name: 'Harry Cover',
        availability: true,
        position: 'Director',
        email: 'stefan@mozart.com',
        phone: '+33 1 44 34 59 16',
        manager: 'Stefan Radstrom',
        role: 'Owner',
        active: true
    },
    {
        id: 2,
        img: img,
        name: 'Harry Cover',
        availability: true,
        position: 'Director',
        email: 'stefan@mozart.com',
        phone: '+33 1 44 34 59 16',
        manager: 'Stefan Radstrom',
        role: 'Owner',
        active: true
    },
    {
        id: 3,
        img: img,
        name: 'Harry Cover',
        availability: false,
        position: 'Director',
        email: 'stefan@mozart.com',
        phone: '+33 1 44 34 59 16',
        manager: 'Stefan Radstrom',
        role: 'Owner',
        active: true
    },
    {
        id: 4,
        img: img,
        name: 'Harry Cover',
        availability: true,
        position: 'Director',
        email: 'stefan@mozart.com',
        phone: '+33 1 44 34 59 16',
        manager: 'Stefan Radstrom',
        role: 'Owner',
        active: true
    },
    {
        id: 5,
        img: img,
        name: 'Harry Cover',
        availability: false,
        position: 'Director',
        email: 'stefan@mozart.com',
        phone: '+33 1 44 34 59 16',
        manager: 'Stefan Radstrom',
        role: 'Owner',
        active: true
    },
    {
        id: 6,
        img: img,
        name: 'Harry Cover',
        availability: true,
        position: 'Director',
        email: 'stefan@mozart.com',
        phone: '+33 1 44 34 59 16',
        manager: 'Stefan Radstrom',
        role: 'Owner',
        active: true
    }
];