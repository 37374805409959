import { colors } from './HotelData';

export const trafficSourceOptions = {
    responsive: true,
    interaction: {
        mode: "index",
        intersect: false
    },
    stacked: false,
    plugins: {
        title: {
            display: false
        }
    },
    scales: {
        y: {
            display: true,
            beginAtZero: true,
            suggestedMax: 70,
        }
    }
}

const trafficSourceLabels = ["Apr 27", "May 4", "May 11", "May 18", "May 25", "Jun 1", "Jun 8", "Jun15", "Jun 22", "Jun 19", "Jul 6"]

export const trafficSourceData = {
    labels: trafficSourceLabels,
    datasets: [
        {
            label: "Couple",
            data: [22, 34, 36, 33, 38, 35, 39, 36, 36, 34, 30,],
            borderColor: `${colors[0]}80`,
            backgroundColor: colors[0]
        },
        {
            label: "Family",
            data: [24, 24, 33, 36, 35, 34, 35, 31, 31, 30, 29],
            borderColor: `${colors[1]}80`,
            backgroundColor: colors[1]
        },
        {
            label: "Business",
            data: [23, 35, 34, 34, 32, 33, 37, 34, 33, 32, 31],
            borderColor: `${colors[2]}80`,
            backgroundColor: colors[2]
        },
        {
            label: "Leisure",
            data: [20, 28, 35, 35, 34, 31, 36, 32, 37, 33, 32],
            borderColor: `${colors[3]}80`,
            backgroundColor: colors[3]
        }
    ]
}