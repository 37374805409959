import React, { useState, useEffect } from 'react'

import arrowLeft from '../../assets/icons/arrow-left.svg'
import arrowRight from '../../assets/icons/arrow-right.svg'
import { getContacts } from '../../utils/mock/ContactsData'
import { Table } from '../../components/Table/Table'
import { MdSearch } from 'react-icons/md'
import { PiCaretUpDownLight } from 'react-icons/pi'
import { TbFilterPlus } from 'react-icons/tb'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md'
import { BsPersonSquare } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { getUser } from '../../api/user'

const cells = [
    'Name',
    'Availability',
    'Position',
    'Email',
    'Phone',
    'Manager',
    'Role',
]

export const HotelContacts = ({ handleLogout }) => {
    const [contact, setContact] = useState([])
    const [currentPageData, setCurrentPageData] = useState(0)
    const [edit, setEdit] = useState(false);

    const formatData = (data) => {
        let res = []
        data.map((contact) => {
            return res.push({
                id: contact?.sub,
                img: contact?.img || null,
                name: contact?.firstname + ' ' + contact.lastname,
                availability: contact?.available || false,
                position: contact?.position || '___',
                email: contact?.email,
                phone: contact?.phone,
                manager: '___',
                role: contact?.roles[0] === 'hotelManager' ? 'Manager' : contact?.roles[0] === 'hotelAccountant' ? 'Accountant' : contact?.roles[0] === 'hotelStaff' ? 'Staff' : contact?.roles[0]
            })
        })
        return res
    }



    const handleEdit = () => {
        if (edit) {
            // TODO API call to save updates
            setEdit(false);
        } else {
            setEdit(true);
        }
    }

    /*   useEffect(() => {
            let token = localStorage.getItem('auth_token')
            if (token) {
                getContacts((response) => {
                    if (response?.status === 'success') {
                        setContact(formatData(response.content));
                    }
                })
    
                const token_exp = JSON.parse(localStorage.getItem('token_exp'))
                if (token_exp < Date.now() / 1000) {
                    handleLogout()
                }
            }
        }, [handleLogout])
        */

    return (
        <div className="page-container">
            <div className="row-view justify-between">
                <div>
                    <div className="size2 bold">Contact</div>
                    <div className="size1 bold today">
                        <span>{contact.length} contacts</span>
                    </div>
                </div>
                <div className="row-view">
                    <div className="row-view">
                        <button className="secondary-button borderless row-view items-center">
                            <div className="mr5">
                                <TbFilterPlus />
                            </div>
                            <div>Filter</div>
                        </button>
                        <div className="secondary-search-bar-container row-view items-center justify-center">
                            <div className="mr10">
                                <MdSearch size={20} />
                            </div>
                            <input
                                type="text"
                                placeholder="Search reservations"
                                className="secondary-search-bar"
                            />
                        </div>
                    </div>
                    <div className="row-view justify">
                        <button
                            className="secondary-button w160 borderless row-view items-center justify-center"
                            onClick={() => handleEdit()}
                        >
                            <div className="mr5">
                                <BsPersonSquare />
                            </div>
                            <div>{edit ? 'Save' : 'Edit roles'}</div>
                        </button>
                        <Link
                            className="primary-button none-decoration-link row-view items-center"
                            to={{ pathname: '/contact/add' }}
                        >
                            <AiOutlinePlusCircle
                                color="white"
                                size={20}
                                className="mr10"
                            />
                            <div>Add Contact</div>
                        </Link>
                    </div>
                </div>
            </div>
            <button className="secondary-button row-view items-center p10 mt20">
                <div
                    style={{
                        backgroundColor: 'gray',
                        width: 25,
                        height: 25,
                        borderRadius: 50,
                        marginRight: 5,
                    }}
                />
                <div className="mr5">The Mozart Hotel</div>
                <MdArrowDropDown size={20} />
            </button>

            <div className="mt50 of-scroll">
                <Table cells={cells} data={contact} type='contact' edit={edit} />
            </div>

            <div className="row-view items-center justify-between mt50 ">
                <div className="row-view items-center">
                    <button className="secondary-button row-view p10 mr10">
                        <div className="mr5">25</div>
                        <PiCaretUpDownLight />
                    </button>
                    <div className="section-title">profiles per page</div>
                </div>
                <div className="pagination">
                    <div
                        className="pagination--item"
                        onClick={() =>
                            setCurrentPageData(
                                currentPageData === 0
                                    ? currentPageData
                                    : currentPageData - 1
                            )
                        }
                    >
                        <img src={arrowLeft} alt="dashboard" className="icon" />
                    </div>
                    <div
                        className="pagination--item"
                        onClick={() => setCurrentPageData(currentPageData + 1)}
                    >
                        <img
                            src={arrowRight}
                            alt="dashboard"
                            fill="red"
                            className="icon pagination--color"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
