import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAllHotels } from "../../api/hotel";
import { getMyAgencyHotelList } from '../../api/my_agency';
import { MdSearch } from 'react-icons/md';
import { Pagination } from '../../components/Generic/Pagination';
import { PiCaretUpDownLight } from "react-icons/pi";
import { Table } from "../../components/Table/Table.js";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import { AiOutlinePlusCircle } from 'react-icons/ai';

export const HotelsAll = ({ handleLogout, searchText }) => {
    const queryParams = new URLSearchParams(window.location.search)
    const [hotels, setHotels] = useState([]);
    const [edit, setEdit] = useState(false);
    const navigate = useNavigate();
    const [hotelsCount, setHotelsCount] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hotelsPerPage, setHotelsPerPage] = useState(10);
    const [pagesNb, setPagesNb] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const text = searchText;


    const cells = [
        'Name',
        'Owner',
        'Manager',
        'City',
        'Zip code',
        'Address',
        'Website'
    ]

    const limit = [5, 10, 15, 20, 25]

    const formatData = (data) => {
        let res = [];
        data.map((h) => {
            return (
                res.push({
                    'id': h?.sub,
                    'img': h?.showcase?.logo || null,
                    'name': h?.name || h?.content?.myagency?.Name,
                    'owner': h?.owner.length !== 0 ? `${h?.owner[0]?.firstname} ${h?.owner[0]?.lastname}` : '___',
                    'manager': h?.manager.length !== 0 ? `${h?.manager[0]?.firstname} ${h?.manager[0]?.lastname}` : '___',
                    'city': `${h?.address?.city}, ${h?.address?.country}` || '___',
                    'zipcode': h?.address?.zip_code || '___',
                    'address': h?.address?.line1 || '___',
                    'website': h?.website || h?.content?.myagency?.WebSiteUrl || '___',
                    // 'active': h?.active
                })
            );
        })
        return res;
    }

    const retrieveHotels = (offset, limit, search) => {
        getAllHotels(offset, limit, search, response => {
            setIsLoading(false)
            if (response.status === 'success') {
                setHotels(formatData(response.content.hotels));
                setHotelsCount(response.content.count);
                setPagesNb(Math.ceil(response?.content?.count / limit))
            }
        });
    }

    useEffect(() => {
        let token = (localStorage.getItem('auth_token'));
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem('token_exp'));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }

        }
    }, [handleLogout]);


    useEffect(() => {
        console.log('pass')
        navigate(`/hotels?page=${0}&limit=${hotelsPerPage}&search=${searchText}`)
        retrieveHotels(0, hotelsPerPage, searchText, response => {
            if (response?.status === 'success') {
                setHotels(formatData(response?.content?.hotels))
            }
        })

    }, [searchText])


    console.log(isLoading)
    return (
        <>
            {isLoading ?
                <div className='row-view flex1 justify-center items-center'>
                    <div className='loader' />
                </div>
                :
                <div className='page-container'>

                    <div className='column-view flex1'>
                        <div>
                            <div className='of-scroll mt20'>
                                <Table cells={cells} data={hotels.length !== 0 ? hotels : 'No hotels yet...'} type='hotels' edit={edit} currentPage={currentPage} />
                            </div>
                            <Pagination
                                type='Hotels'
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={hotelsPerPage}
                                pagesNb={pagesNb}
                                setItemsPerPage={setHotelsPerPage}
                                path='hotels'
                                retrieveData={retrieveHotels}
                                searchText={searchText}
                            />
                        </div>

                    </div>
                </div>
            }
        </>
    )

};
