import { API_URL } from "./const";

export const login = async (email, password, callback) => {
  fetch(`${process.env.REACT_APP_API_URL}/login/login_by_email`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};
