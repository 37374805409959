import React, { useEffect, useState } from 'react';
import graphIcon from '../../assets/icons/Graph.svg';
import { data, colors } from '../../utils/mock/HotelData';
import { trafficSourceOptions, trafficSourceData, } from '../../utils/mock/TrafficSourceData';
import { newReservationOptions, newReservationData } from '../../utils/mock/NewReservationData';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js"
import { Line } from "react-chartjs-2"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const GraphIcon = () => {
    return (
        <img
            src={graphIcon}
            alt='todaysOrder'
            className='icon'
        />
    )
}

export const Dashboard = ({ handleLogout }) => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        let token = (localStorage.getItem('auth_token'));
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem('token_exp'));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }
        }

        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleLogout]);

    return (
        <div className='page-container'>
            <div className={`${windowSize < 965 ? 'grid-view2' : 'row-view'}`}>
                <div className='dashboard-section flex1 mr20'>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>Today's order</div>
                    </div>
                    <div className='ml20 section-subtitle'>Volume</div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    <div className='column-view items-center justify-center pv20'>
                        <div className='row-view items-center p10'>
                            <div className='purple-square16 mr10' />
                            <div className='section-secondary-title'>Total orders - Volume</div>
                        </div>
                        <div className='xl-text'>{data.todays_order}</div>
                        <div className='section-subtitle'>Reservations</div>
                    </div>
                </div>
                <div className={`dashboard-section flex1 ${windowSize > 968 && 'mr20'}`}>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>Profit</div>
                    </div>
                    <div className='ml20 section-subtitle'>Today</div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    <div className='column-view items-center justify-center pv20'>
                        <div className='row-view items-center p10'>
                            <div className='purple-square16 mr10' />
                            <div className='section-secondary-title'>Net Revenue - By reservations</div>
                        </div>
                        <div className='xl-text'>{data.net_revenue}</div>
                        <div className='section-subtitle'>euros</div>
                    </div>
                </div>
                <div className={`dashboard-section flex1 ${windowSize < 965 && 'mr20 mt20'}`}>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>Top Hotels by revenue</div>
                    </div>
                    <div className='ml20 section-subtitle'>Last 30 days</div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    <div className='column-view items-center justify-center p20'>
                        {data.top_hotels.sort((a, b) => parseInt(b.revenue) - parseInt(a.revenue)).map((hotel, index) => {
                            return (
                                <div key={index} className={`w100 mb10 row-view items-center`}>
                                    <div className={`section-secondary-title flex04 mr10 ${windowSize > 1200 && 'text-end'}`}>{hotel.name}</div>
                                    <div className='section-secondary-title row-view items-center flex06 w100'>
                                        <div
                                            style={{
                                                backgroundColor: colors[index],
                                                width: `${(hotel.revenue * 100) / 100}%`,
                                                height: 20,
                                                borderRadius: 6,
                                                marginRight: 10,
                                            }} />
                                        <div>{hotel.revenue}K€</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {windowSize < 965 &&
                    <div className='dashboard-section flex1 mt20'>
                        <div className='row-view items-center p20 pb5'>
                            <GraphIcon />
                            <div className='section-title ml10'>Income earned</div>
                        </div>
                        <div className='ml20 section-subtitle'>Last 30 days</div>
                        <div className='h-separator bgE9E9E9 mt20' />
                        <div className='column-view items-center justify-center pv20'>
                            <div className='row-view items-center p10'>
                                <div className='purple-square16 mr10' />
                                <div className='section-secondary-title'>Net Revenue - By reservations</div>
                            </div>
                            <div className='xl-text'>{data.income_earned}</div>
                            <div className='section-subtitle'>euros</div>
                        </div>
                    </div>
                }
            </div>

            <div className='dashboard-section flex1 mt20'>
                <div className='row-view items-center p20 pb5'>
                    <GraphIcon />
                    <div className='section-title ml10'>Traffic Sources</div>
                </div>
                <div className='ml20 section-subtitle'>Last 30 days</div>
                <div className='h-separator bgE9E9E9 mt20' />
                <div className='pv20 w100'>
                    <Line options={trafficSourceOptions} data={trafficSourceData} height={100} />
                </div>
            </div>

            <div className={`row-view mt20 ${windowSize < 965 && 'grid-view2'}`}>
                {windowSize > 965 &&
                    <div className='dashboard-section flex1 mr20'>
                        <div className='row-view items-center p20 pb5'>
                            <GraphIcon />
                            <div className='section-title ml10'>Income earned</div>
                        </div>
                        <div className='ml20 section-subtitle'>Last 30 days</div>
                        <div className='h-separator bgE9E9E9 mt20' />
                        <div className='column-view items-center justify-center pv20'>
                            <div className='row-view items-center p10'>
                                <div className='purple-square16 mr10' />
                                <div className='section-secondary-title'>Net Revenue - By reservations</div>
                            </div>
                            <div className='xl-text'>{data.income_earned}</div>
                            <div className='section-subtitle'>euros</div>
                        </div>
                    </div>
                }
                <div className='dashboard-section flex1 mr20'>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>New Reservations</div>
                    </div>
                    <div className='ml20 section-subtitle'>Last 30 days</div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    <div className='pv20 w100'>
                        <Line options={newReservationOptions} data={newReservationData} />
                    </div>
                </div>
                <div className='dashboard-section flex1'>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>New Travelers</div>
                    </div>
                    <div className='ml20 section-subtitle'>Last 30 days</div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    <div className='column-view items-center justify-center pv20'>
                        <div className='row-view items-center p10'>
                            <div className='purple-square16 mr10' />
                            <div className='section-secondary-title'>Reservations - By New Travelers</div>
                        </div>
                        <div className='xl-text'>{data.new_travelers}</div>
                        <div className='section-subtitle'>Reservations</div>
                    </div>
                </div>
            </div>
        </div >
    )
}