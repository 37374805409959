import React, { useEffect, useState } from "react";
import { eventsData, colors, fontColors } from "../../utils/mock/EventsData";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiSolidChevronDown } from "react-icons/bi";
import { MdCheck } from "react-icons/md";
import { getAllStaff } from "../../api/staff";
import { FaArrowRightLong } from "react-icons/fa6";
import { createRoot } from "react-dom/client";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { CheckCalendar } from "../HotelCalendar/CheckCalendar";
import moment from "moment";
import { BookedCalendar } from "../HotelCalendar/BookedCalendar";
import staffSvg from "../../assets/icons/Staff.svg";

export const HotelCalendar = ({ handleLogout }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [staff, setStaff] = useState([]);
  const [addeventModal, setAddeventModal] = useState(false);
  const [addNewEventModal, setNewEventModal] = useState(false);
  const [addNewMoodModal, setNewMoodModal] = useState(false);
  const [addNewStaffModal, setNewStaffModal] = useState(false);
  const [publicId, setInstanceId] = useState([]);
  const [openType, setOpenType] = useState(false);
  const [openAudience, setOpenAudience] = useState(false);
  const [openStaff, setOpenStaff] = useState(false);
  const [eventType, setEventType] = useState(["Event", "Mood", "Staff"]);
  const eventCountByEndDate = [];
  const eventCountByStartDate = [];
  const currentDate = moment();
  const momentDate = currentDate.format("MMMM DD, YYYY");
  const staffOut = [];

  const [event, setEvent] = useState({
    type: "",
    name: "",
    date: "",
    event: [],
    mood: [],
    staff: [],
    audience: [],
  });

  const audience = [
    "Business",
    "Bleisure",
    "Couple",
    "Friends",
    "Family",
    "Party",
    "Sport",
    "Relax",
  ];

  const formatDataStaff = (data) => {
    let res = [];
    data.map((staff) => {
      return res.push({
        id: staff?.sub,
        img: staff?.img || null,
        name: staff?.firstname + " " + staff.lastname,
        availability: staff?.available,
        position: staff?.position || "___",
        email: staff?.email,
        phone: staff?.phone,
        manager:
          staff?.manager?.length !== 0
            ? `${staff?.manager[0]?.firstname} ${staff?.manager[0]?.lastname}`
            : "___",
        role:
          staff?.roles[0] === "hotelManager"
            ? "Manager"
            : staff?.roles[0] === "hotelAccountant"
              ? "Accountant"
              : staff?.roles[0] === "hotelStaff"
                ? "Staff"
                : staff?.roles[0],
      });
    });
    return res;
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (token) {
      getAllStaff((response) => {
        if (response?.status === "success") {
          setStaff(
            formatDataStaff(
              response.content.sort((a, b) =>
                a.firstname.localeCompare(b.firstname)
              )
            )
          );
        }
      });

      const token_exp = JSON.parse(localStorage.getItem("token_exp"));
      if (token_exp < Date.now() / 1000) {
        handleLogout();
      }
    }

    const header = document.querySelector(".fc-view-harness");

    const element = createRoot(header);

    const newRow = (
      <div className="row-view">
        <div className="row-view items-center mr20">
          <div
            className="mr5"
            style={{
              height: "15px",
              width: "15px",
              borderRadius: "25px",
              backgroundColor: "#59B19F4D",
            }}
          ></div>
          <div>Check-in</div>
        </div>
        <div className="row-view items-center mr20">
          <div
            className="mr5"
            style={{
              height: "15px",
              width: "15px",
              borderRadius: "25px",
              backgroundColor: "#E085704D",
            }}
          ></div>
          <div>Check-out</div>
        </div>
        <div className="row-view items-center mr20">
          <div
            className="mr5"
            style={{
              height: "15px",
              width: "25px",
              borderRadius: "25px",
              backgroundColor: `${colors[2]}33`,
            }}
          ></div>
          <div>Hotel mood</div>
        </div>
        <div className="row-view items-center mr20">
          <div
            className="mr5"
            style={{
              height: "15px",
              width: "25px",
              borderRadius: "25px",
              backgroundColor: `${colors[3]}33`,
            }}
          ></div>
          <div>Events</div>
        </div>
        <div className="row-view items-center mr20">
          <div
            className="mr5"
            style={{
              height: "15px",
              width: "25px",
              borderRadius: "25px",
              backgroundColor: `#FF3B3B4D`,
            }}
          ></div>
          <div>Hotel status</div>
        </div>
        <div className="row-view items-center mr20">
          <div className="mr5">
            <img src={staffSvg} alt="user" className="icon" />
          </div>
          <div>Staff availability</div>
        </div>
      </div>
    );
    const div3 = document.querySelector(".fc-view-harness"); // Sélectionnez la troisième div

    // Rendez le nouvel élément React dans la deuxième div
    element.render(newRow, div3);

    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleLogout]);

  useEffect(() => {
    // Parcourez les événements et effectuez le comptage
    eventsData.forEach((event) => {
      if (event.tag === "reservation") {
        const formattedEndDate = moment(event.end).format("YYYY-MM-DD");

        const existingEndIndex = eventCountByEndDate.findIndex(
          (item) => item.date === formattedEndDate
        );

        if (existingEndIndex !== -1) {
          eventCountByEndDate[existingEndIndex].count++;
        } else {
          eventCountByEndDate.push({ date: formattedEndDate, count: 1 });
        }

        const formattedStartDate = moment(event.start).format("YYYY-MM-DD");

        const existingStartIndex = eventCountByStartDate.findIndex(
          (item) => item.date === formattedStartDate
        );

        if (existingStartIndex !== -1) {
          eventCountByStartDate[existingStartIndex].count++;
        } else {
          eventCountByStartDate.push({ date: formattedStartDate, count: 1 });
        }
      } else if (event.tag === "staff") {
        const title = event.title;

        const startDate = moment(event.start); // Replace with your start date
        const endDate = moment(event.end); // Replace with your end date
        const dateFormat = "YYYY-MM-DD"; // Replace with your desired date format

        while (startDate.isSameOrBefore(endDate)) {
          const formattedDate = startDate.format(dateFormat);
          const event = staffOut.find((e) => e.date === formattedDate);

          if (!event) {
            staffOut[formattedDate] = { date: formattedDate, title: title };
          }
          startDate.add(1, "day");
        }
      }
    });

    const today = moment().format("YYYY-MM-DD");

    handleDateClick(today);
  });

  const handleSelect = (key, value) => {
    setEvent({ ...event, [key]: [value] });
  };

  const handleInputText = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleDateChange = (key, value) => {
    setEvent({ ...event, [key]: [value] });
  };

  const handleCheckbox = (type, item) => {
    const newEvent = { ...event };

    // Vérifiez si l'élément est déjà présent dans le tableau audience de event

    if (type === "audience") {
      const audienceIndex = newEvent.audience.indexOf(item);

      if (audienceIndex === -1) {
        newEvent.audience.push(item);
      } else {
        newEvent.audience.splice(audienceIndex, 1);
      }
    } else if (type === "staff") {
      const staffIndex = newEvent.staff.indexOf(item);

      if (staffIndex === -1) {
        newEvent.staff.push(item);
      } else {
        newEvent.staff.splice(staffIndex, 1);
      }
    }

    setEvent(newEvent);
  };

  const handleDateClick = (arg) => {
    let dateArg = "";
    const today = moment().format("YYYY-MM-DD");

    if (arg == today) {
      dateArg = today;
    } else {
      dateArg = arg.dateStr;
    }

    let eventsCheckIn = [];
    let eventsCheckOut = [];
    let eventsStaff = [];
    let divDate = document.getElementById("check-date");

    const clickedFormattedDate = moment(arg.dateStr).format("MMMM DD, YYYY");

    eventsData.forEach((element, index) => {
      const eventFormattedStartDate = moment(element.start).format(
        "MMMM DD, YYYY"
      );
      const eventFormattedEndDate = moment(element.end).format("MMMM DD, YYYY");

      if (element.tag === "reservation") {
        if (eventFormattedStartDate === clickedFormattedDate) {
          eventsCheckIn.push({ index: element });
        }
        if (eventFormattedEndDate === clickedFormattedDate) {
          eventsCheckOut.push({ index: element });
        }
      }
      if (element.tag === "staff") {
        let staffOutDate = moment(clickedFormattedDate).format("YYYY-MM-DD");
        if (staffOut[staffOutDate]) {
          eventsStaff.push({ index: element });
        }
      }
    });

    const rootCheckIn = createRoot(document.getElementById("check-in"));
    const rootCheckOut = createRoot(document.getElementById("check-out"));
    const rootStaffOut = createRoot(document.getElementById("staff-out"));

    rootCheckIn.render(
      <div>
        {eventsCheckIn.length === 0 ? (
          <p className="mt10 ml20 mb10">No data found</p>
        ) : (
          eventsCheckIn.map((event, index) => (
            <div key={index}>
              <BookedCalendar events={event} />
            </div>
          ))
        )}
      </div>
    );

    rootCheckOut.render(
      <div>
        {eventsCheckOut.length === 0 ? (
          <p className="mt10 ml20 mb10">No data found</p>
        ) : (
          eventsCheckOut.map((event, index) => (
            <div key={index}>
              <BookedCalendar events={event} />
            </div>
          ))
        )}
      </div>
    );
    rootStaffOut.render(
      <div>
        {eventsStaff.length === 0 ? (
          <p className="mt10 ml20 mb10">No data found</p>
        ) : (
          eventsStaff.map((event, index) => (
            <div key={index}>
              <BookedCalendar events={event} />
            </div>
          ))
        )}
      </div>
    );
    divDate.textContent = clickedFormattedDate;
  };

  const headerToolbar = {
    left: "prev,next today",
    center: "title",
    right: "dayGridMonth,dayGridWeek,dayGridDay",
  };

  const renderEventContent = (eventInfo) => {
    let tag = eventsData.filter(
      (el) => parseInt(el.id) === parseInt(eventInfo.event.id)
    )[0].tag;

    let color = "";
    let fontColor = "";
    let startDate = eventInfo;
    switch (tag) {
      case "reservation":
        color = colors[0];
        fontColor = fontColors[0];
        break;
      case "traveler":
        color = colors[1];
        fontColor = fontColors[1];
        break;
      case "mood":
        color = colors[2];
        fontColor = fontColors[2];
        break;
      case "event":
        color = colors[3];
        fontColor = fontColors[3];
        break;
      default:
        color = "";
        fontColor = "";
    }

    if (!publicId.includes(eventInfo.event._def.publicId)) {
      publicId.push(eventInfo.event._def.publicId);
    }

    const matchingEvent = eventsData.find(
      (el) => parseInt(el.id) === parseInt(eventInfo.event.id)
    );
    const titleToShow = matchingEvent ? matchingEvent.title : "";

    return (
      <>
        {matchingEvent.tag === "reservation" ||
          (matchingEvent.tag === "staff" &&
            publicId.includes(matchingEvent.id.toString())) ? ( //TODO check sub string id
          <div>
            {(() => {
              //forEach date get countStart & countEnd

              let dateCounts = [];
              const uniqueDates = [];

              // Fonction pour mettre à jour dateCounts
              const updateDateCounts = (data, isEndDate) => {
                data.forEach((item) => {
                  const { date, count } = item;

                  if (dateCounts[date]) {
                    dateCounts[date].forEach((entry) => {
                      if (isEndDate) {
                        entry.countEnd += count;
                      } else {
                        entry.countEnd += count; // Incrémenter countEnd
                      }
                    });
                  } else {
                    if (isEndDate) {
                      dateCounts[date] = [
                        {
                          countStart: 0, // Mettre à 0 ou à la valeur initiale souhaitée
                          countEnd: count, // Initialiser countEnd à la valeur de count
                        },
                      ];
                    } else {
                      dateCounts[date] = [
                        {
                          countStart: count, // Mettre à 0 ou à la valeur initiale souhaitée
                          countEnd: 0, // Initialiser countEnd à la valeur de count
                        },
                      ];
                    }
                  }
                });
              };

              // Mettre à jour dateCounts pour countStart
              updateDateCounts(eventCountByStartDate, false);

              // Mettre à jour dateCounts pour countEnd
              updateDateCounts(eventCountByEndDate, true);

              // Créer un tableau pour stocker les dates qui ne sont pas présentes dans les deux tableaux

              // Parcourir les clés du premier tableau
              for (const date1 in staffOut) {
                // Vérifier si cette date n'existe pas dans le deuxième tableau
                if (!(date1 in dateCounts)) {
                  uniqueDates.push(date1);
                }
              }

              uniqueDates.forEach((date) => {
                dateCounts[date] = [
                  {
                    countStart: -1,
                    countEnd: -1,
                  },
                ];
              });

              let dateElements = Object.keys(dateCounts).map((date) => {
                const elements = dateCounts[date];
                if (
                  elements[0].countStart !== 0 &&
                  elements[0].countEnd !== 0 &&
                  elements[0].countStart !== -1 &&
                  elements[0].countEnd !== -1
                ) {
                  // if check in & check out
                  return (
                    <div key={date}>
                      {
                        <CheckCalendar
                          arrayStart={eventCountByStartDate}
                          arrayEnd={eventCountByEndDate}
                          arrayStaff={staffOut}
                          checkInColor={`#59B19F4D`}
                          checkOutColor={`#E085704D`}
                          date={date}
                          countStart={elements[0].countStart}
                          countEnd={elements[0].countEnd}
                          fontCheckInColor={`#428C78`}
                          fontCheckOutColor={`#E4775E`}
                        />
                      }
                    </div>
                  );
                } else if (
                  //if no check out but check in
                  elements[0].countStart !== 0 &&
                  elements[0].countEnd === 0
                ) {
                  return (
                    <div key={date}>
                      {
                        <CheckCalendar
                          arrayStart={eventCountByStartDate}
                          arrayEnd={eventCountByEndDate}
                          arrayStaff={staffOut}
                          checkInColor={`#59B19F4D`}
                          date={date}
                          countStart={elements[0].countStart}
                          fontCheckInColor={`#428C78`}
                        />
                      }
                    </div>
                  );
                } else if (
                  //if no check in but check out
                  elements[0].countStart === 0 &&
                  elements[0].countEnd !== 0
                ) {
                  return (
                    <div key={date}>
                      {
                        <CheckCalendar
                          arrayStart={eventCountByStartDate}
                          arrayEnd={eventCountByEndDate}
                          arrayStaff={staffOut}
                          checkOutColor={`#E085704D`}
                          date={date}
                          countEnd={elements[0].countEnd}
                          fontCheckOutColor={`#E4775E`}
                        />
                      }
                    </div>
                  );
                } else if (
                  elements[0].countStart === -1 &&
                  elements[0].countEnd === -1
                ) {
                  return (
                    <div key={date}>
                      {
                        <CheckCalendar
                          arrayStart={eventCountByStartDate}
                          arrayEnd={eventCountByEndDate}
                          arrayStaff={staffOut}
                          checkOutColor={`#E085704D`}
                          date={date}
                          countStart={-1}
                          countEnd={-1}
                          fontCheckOutColor={`#E4775E`}
                        />
                      }
                    </div>
                  );
                } else {
                  return null;
                }
              });

              return <div>{[...dateElements]}</div>;
            })()}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: `${color}33`,
              color: `${fontColor}`,
              fontWeight: "600",
              fontSize: windowSize < 1200 ? 12 : 14,
              paddingTop: windowSize < 1200 ? 4 : 6,
              paddingLeft: 10,
              paddingBottom: 10,
              borderRadius: 25,
              marginBottom: "10px",
              height: windowSize < 1200 ? 20 : 25,
            }}
          >
            {titleToShow}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="page-container">
      {addeventModal && (
        <div
          className="filter-darker"
          onClick={() => {
            if (addeventModal) {
              setAddeventModal(false);
            }
          }}
        />
      )}
      {addNewEventModal && (
        <div
          className="filter-darker"
          onClick={() => {
            if (addNewEventModal) {
              setNewEventModal(false);
            }
          }}
        />
      )}
      {addNewMoodModal && (
        <div
          className="filter-darker"
          onClick={() => {
            if (addNewMoodModal) {
              setNewMoodModal(false);
            }
          }}
        />
      )}
      {addNewStaffModal && (
        <div
          className="filter-darker"
          onClick={() => {
            if (addNewStaffModal) {
              setNewStaffModal(false);
            }
          }}
        />
      )}

      <div className="row-view items-center justify-between mb50">
        <div className="size2 bold">Calendar</div>
        <button
          className="primary-button row-view items-center"
          onClick={() => setAddeventModal(!addeventModal)}
        >
          <AiOutlinePlusCircle color="white" size={20} className="mr10" />
          <div>Add Event</div>
        </button>

        <div className={`${addeventModal && "active"}`}>
          <div
            className={`black-text column-view text-start p20 addevent-modal ${addeventModal && "active"
              }`}
          >
            <div className="black-text mb20 bold">Select event</div>
            <div
              className="row-view mb20 cursor-pointer"
              onClick={() => {
                setNewEventModal(!addNewEventModal);
                handleSelect("type", "Event");
              }}
            >
              <div className="orange-square mr10"></div>
              <div>Event</div>
            </div>
            <div
              className="row-view mb20 cursor-pointer"
              onClick={() => {
                setNewMoodModal(!addNewMoodModal);
                handleSelect("type", "Mood");
              }}
            >
              <div className="blue-square mr10"></div>
              <div>Mood</div>
            </div>
            <div
              className="row-view cursor-pointer"
              onClick={() => {
                setNewStaffModal(!addNewStaffModal);
                handleSelect("type", "Staff");
              }}
            >
              <div className="red-square mr10"></div>
              <div>Availability Staff</div>
            </div>
          </div>
        </div>
        <div
          className={`black-text column-view text-start p20 newevent-modal ${(addNewEventModal || addNewMoodModal) && "active"
            }`}
        >
          <div>
            <div className="black-text mb20">Select event</div>
            <div
              className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer"
              onClick={() => {
                setOpenType(!openType);
              }}
            >
              <div
                className={`p10 r8 ${event.type.toString() === "Staff"
                  ? "red-text redbg01"
                  : event.type.toString() === "Event"
                    ? "orange-text orangebg01"
                    : event.type.toString() === "Mood" && "blue-text bluebg01"
                  }
`}
              >
                {event.type !== "" ? event.type : "Select type"}
              </div>
              <BiSolidChevronDown />
            </div>
            <div className={`select-menu ${openType && "open"}`}>
              {eventType.length !== 0 ? (
                <>
                  {eventType.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="select-item"
                        onClick={() => {
                          handleSelect("type", item);
                          setOpenType(false);
                          if (index === 0) {
                            setNewEventModal(true);
                            setNewMoodModal(false);
                            setNewStaffModal(false);
                          } else if (index === 1) {
                            setNewMoodModal(true);
                            setNewEventModal(false);
                            setNewStaffModal(false);
                          } else if (index === 2) {
                            setNewEventModal(false);
                            setNewMoodModal(false);
                            setNewStaffModal(true);
                          }
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="select-item">No type found</div>
                </>
              )}
            </div>
          </div>
          <div>
            <div className="black-text mb20 mt20">Select date</div>
            <input type="date" className="input-text w300 ph20 pv20" />
          </div>

          <div>
            <div className="black-text mb20 mt20">Event name</div>

            <input
              className="input-text w300 ph20 pv20"
              name="eventName"
              onChange={(e) => handleInputText(e)}
            />
          </div>
          <div>
            <div className="black-text mb20 mt20">Target audience</div>
            <div
              className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv20 ph20 cursor-pointer"
              onClick={() => {
                setOpenAudience(!openAudience);
              }}
            >
              {"Select audience"}
              <BiSolidChevronDown />
            </div>
            <div
              className={`select-menu w60p h25vh ${openAudience && "open"} `}
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              {audience.length !== 0 ? (
                <>
                  {audience.map((item, index) => {
                    return (
                      <div
                        className="bgFAFAFA row-view items-center p20 b r8 cursor-pointer "
                        onClick={() => {
                          handleCheckbox("audience", item);
                        }}
                      >
                        <div
                          key={index}
                          className={`checkbox r4 mr10 ${event.audience.includes(item) ? "selected" : ""
                            } `}
                          onClick={() => {
                            handleSelect("audience", item);
                          }}
                        >
                          {event.audience.includes(item) && (
                            <MdCheck color="white" />
                          )}
                        </div>
                        <div>{item}</div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="select-item">No audience found</div>
                </>
              )}
            </div>
            <div className="row-view justify-around mt50">
              <div
                className="secondary-button"
                type="reset"
                onClick={() => {
                  setAddeventModal(false);
                  setNewEventModal(false);
                  setNewMoodModal(false);
                  setNewStaffModal(false);
                }}
              >
                Cancel
              </div>
              <div className="primary-button" type="submit">
                Save
              </div>
            </div>
          </div>
        </div>

        <div
          className={`black-text column-view text-start p20 newevent-modal ${addNewStaffModal && "active"
            }`}
        >
          <div>
            <div className="black-text mb20">Select event</div>
            <div
              className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer"
              onClick={() => {
                setOpenType(!openType);
              }}
            >
              <div
                className={`p10 r8 
        ${event.type.toString() === "Staff"
                    ? "red-text redbg01"
                    : event.type.toString() === "Event"
                      ? "orange-text orangebg01"
                      : event.type.toString() === "Mood" && "blue-text bluebg01"
                  }
    `}
              >
                {event.type !== "" ? event.type : "Select type"}
              </div>
              <BiSolidChevronDown />
            </div>
            <div className={`select-menu ${openType && "open"}`}>
              {eventType.length !== 0 ? (
                <>
                  {eventType.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="select-item"
                        onClick={() => {
                          handleSelect("type", item);
                          setOpenType(false);
                          if (index === 0) {
                            setNewEventModal(true);
                            setNewMoodModal(false);
                            setNewStaffModal(false);
                          } else if (index === 1) {
                            setNewMoodModal(true);
                            setNewEventModal(false);
                            setNewStaffModal(false);
                          } else if (index === 2) {
                            setNewEventModal(false);
                            setNewMoodModal(false);
                            setNewStaffModal(true);
                          }
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="select-item">No type found</div>
                </>
              )}
            </div>
          </div>
          <div>
            <div className="black-text mb20 mt20">Select date</div>
            <input type="date" className="input-text w300 ph20 pv20" />
          </div>
          <div>
            <div className="black-text mb20 mt20">Staff list</div>
            <div
              className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv20 ph20 cursor-pointer"
              onClick={() => {
                setOpenStaff(!openStaff);
              }}
            >
              {"Select staff"}
              <BiSolidChevronDown />
            </div>
            <div
              className={`select-menu w60p h25vh ${openStaff && "open"} `}
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              {staff.length !== 0 ? (
                <>
                  {staff.map((item, index) => {
                    return (
                      <div
                        className="bgFAFAFA row-view items-center p20 b r8 cursor-pointer "
                        onClick={() => {
                          handleCheckbox("staff", item.name);
                        }}
                      >
                        <div
                          key={index}
                          className={`checkbox r4 mr10 ${event.staff.includes(item.name) ? "selected" : ""
                            } `}
                          onClick={() => {
                            handleSelect("staff", item.name);
                          }}
                        >
                          {event.staff.includes(item.name) && (
                            <MdCheck color="white" />
                          )}
                        </div>
                        <div>{item.name}</div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="select-item">No staff found</div>
                </>
              )}
            </div>
            <div className="row-view justify-around mt50">
              <div
                className="secondary-button"
                type="reset"
                onClick={() => {
                  setAddeventModal(false);
                  setNewEventModal(false);
                  setNewMoodModal(false);
                  setNewStaffModal(false);
                }}
              >
                Cancel
              </div>
              <div className="primary-button">Save</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row-view">
        <div className="column-view flex07 mr10">
          <FullCalendar
            headerToolbar={headerToolbar}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            dateClick={(e) => {
              handleDateClick(e);
            }}
            events={eventsData}
            eventContent={renderEventContent}
            eventBackgroundColor="transparent"
            eventBorderColor="transparent"
            aspectRatio={1.5}
          />
        </div>
        <div
          id="check-container"
          className="border-container bgFAFAFA flex03 r8 of-scroll h64vh"
          style={{ overflowY: "auto" }}
        >
          <div className="relative">
            <div
              id="check-date"
              className="green-text-front bold ml20 mt50 size2 "
            >
              {momentDate}
            </div>
            <div className="green-background-text"></div>
          </div>
          <div>
            <div
              className="row-view items-center mt20 ml20 bold size1"
              style={{ color: "#5E9A37" }}
            >
              <FaArrowRightLong
                color="#5E9A37"
                style={{ marginRight: "10px" }}
              />
              Check-In of the day
            </div>
            <div id="check-in"></div>
          </div>
          <div>
            <div
              className="row-view items-center mt20 ml20 bold size1"
              style={{ color: "#E4775E" }}
            >
              <FaArrowRightLong
                color="#E4775E"
                style={{ marginRight: "10px" }}
              />
              Check-Out of the day
            </div>
            <div id="check-out"></div>
          </div>
          <div>
            <div
              className="row-view items-center mt50  ml20 bold size1"
              style={{ color: "#DE7979" }}
            >
              <div className="mr5">
                <img src={staffSvg} alt="user" className="icon" />
              </div>
              Staff out of the day
            </div>
            <div id="staff-out"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
