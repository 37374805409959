import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "./calendar.css";
import { AppBar } from "./components/AppBar/AppBar";
import { Drawer } from "./components/Drawer/Drawer";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Login } from "./pages/Login/Login";
import { login } from "./api/auth";
import { getUser } from "./api/user";
import { Hotels } from "./pages/Hotels/Hotels";
import { AddHotel } from "./pages/Hotels/AddHotel";
import { AddTicket } from "./pages/Tickets/AddTicket";
import { Tickets } from "./pages/Tickets/Tickets";
import { EditTicket } from "./pages/Tickets/EditTicket";
import { Contacts } from "./pages/Contacts/Contacts";
import { HotelProfile } from "./pages/Hotels/HotelProfile";
import { Members } from "./pages/Members/Members";
import { Discussions } from "./pages/Discussions/Discussions";
import { AddContact } from "./pages/Contacts/AddContact";
import { AddMember } from "./pages/Members/AddMember";
import { Settings } from "./pages/Settings/Settings";
import { ContactProfile } from "./pages/Contacts/ContactProfile";

const AppContentLoggedIn = forwardRef(({ handleLogout, user }, navigateRef) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const navigateTo = (path) => {
    navigate(path);
  };

  navigateRef.current = { navigateTo };

  return (
    <div className="app-container">
      <AppBar handleLogout={handleLogout} user={user} />
      <div className="page-content">
        <Drawer pathname={pathname} />
        <Routes>
          <Route
            path="/dashboard"
            element={<Dashboard handleLogout={handleLogout} />}
          />
          <Route
            path="/hotels"
            element={<Hotels handleLogout={handleLogout} />}
          />
          <Route
            path="/hotels/add"
            element={<AddHotel handleLogout={handleLogout} />}
          />
          <Route
            path="/hotels/profile/:id"
            element={<HotelProfile handleLogout={handleLogout} />}
          />
          <Route
            path="/tickets"
            element={<Tickets handleLogout={handleLogout} />}
          />
          <Route
            path="/tickets/add"
            element={<AddTicket handleLogout={handleLogout} />}
          />
          <Route
            path="/tickets/edit/:id"
            element={<EditTicket handleLogout={handleLogout} />}
          />
          <Route
            path="/contacts"
            element={<Contacts handleLogout={handleLogout} />}
          />
          <Route
            path="/contact/add"
            element={<AddContact handleLogout={handleLogout} />}
          />
          <Route
            path="/contact/profile/:id"
            element={<ContactProfile handleLogout={handleLogout} />}
          />
          <Route
            path="/members"
            element={<Members handleLogout={handleLogout} />}
          />
          <Route
            path="/member/add"
            element={<AddMember handleLogout={handleLogout} />}
          />
          <Route
            path="/discussions"
            element={<Discussions handleLogout={handleLogout} />}
          />
          <Route
            path="/settings"
            element={<Settings handleLogout={handleLogout} />}
          />
        </Routes>
      </div>
    </div>
  );
});

const AppContentLoggedOut = ({ setAuthToken, setUserSession }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = (email, password) => {
    setErrorMessage("");
    login(email, password, (response) => {
      if (response?.status === "success") {
        setAuthToken(response.auth_token);
        localStorage.setItem("auth_token", JSON.stringify(response.auth_token));
        localStorage.setItem("sub", JSON.stringify(response.sub));
        localStorage.setItem("token_exp", response.exp);
        setUserSession();
        navigate("/dashboard");
      } else {
        setErrorMessage(response.message);
      }
    });
  };

  return <Login handleLogin={handleLogin} errorMessage={errorMessage} />;
};

const App = () => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const navigateRef = useRef(null);
  const navigateTo = (path) => {
    if (navigateRef.current) {
      navigateRef.current.navigateTo(path);
    }
  };

  const setUserSession = () => {
    const sub = JSON.parse(localStorage.getItem("sub"));
    getUser(sub, (response) => {
      setUser(response.data);
    });
  };

  const handleLogout = () => {
    localStorage.clear();
    setAuthToken(null);
    navigateTo("/");
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (token) {
      setAuthToken(token);

      setUserSession();

      const token_exp = JSON.parse(localStorage.getItem("token_exp"));
      if (token_exp < Date.now() / 1000) {
        handleLogout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      {authToken ? (
        <AppContentLoggedIn
          handleLogout={handleLogout}
          user={user}
          ref={navigateRef}
        />
      ) : (
        <AppContentLoggedOut
          setAuthToken={setAuthToken}
          setUserSession={setUserSession}
        />
      )}
    </Router>
  );
};

export default App;
