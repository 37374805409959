import { API_URL } from "./const";

const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth_token'));
}

export const getUser = async (sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/user/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: authToken
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};