import { API_URL } from "./const";

const getToken = async () => {
  return JSON.parse(localStorage.getItem("auth_token"));
};

export const getConversationList = async (callback) => {
  const authToken = await getToken();
  fetch(`${process.env.REACT_APP_API_URL}/conversation`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const sendMessage = async (conversation_sub, message, callback) => {
  const authToken = await getToken();
  fetch(`${process.env.REACT_APP_API_URL}/conversation/${conversation_sub}/message`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    method: "PUT",
    body: JSON.stringify(message),
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getConversation = async (conversation_sub, callback) => {
  const authToken = await getToken();
  fetch(`${process.env.REACT_APP_API_URL}/conversation/${conversation_sub}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};
