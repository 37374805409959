import React, { useState } from 'react';
import logoTextWhite from '../../assets/logos/logo-text-white.svg'
import { MdPerson, MdSearch } from 'react-icons/md';
import { AiFillBell } from 'react-icons/ai';
import { BiSolidChevronDown } from 'react-icons/bi'
import { Link } from 'react-router-dom'

export const AppBar = ({ handleLogout, user }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className='app-bar items-center'>
            <div className='flex1'>
                <img
                    src={logoTextWhite}
                    alt='logoTextWhite'
                    className='logo-small ml50'
                />
            </div>
            <div className='row-view items-center search-bar-container flex1'>
                <MdSearch color='white' size={30} />
                <input type='text' className='search-bar' placeholder='Search' />
            </div>
            <div className='row-view flex1 justify-end items-center mr50'>
                <AiFillBell color='white' size={30} className='mr10' />
                <div className='relative row-view justify-between items-center' >
                    <MdPerson color='white' size={30} className='mr20' />
                    <div className='column-view mr20'>
                        <div className='app-username'>{user?.firstname} {user?.lastname}</div>
                        <div className='app-role'>
                            {user?.roles[0] === 'hotelManager' ?
                                'Manager'
                                :
                                user?.roles[0] === 'hotelAccountant' ?
                                    'Accountant'
                                    :
                                    user?.roles[0] === 'hotelStaff' ?
                                        'Staff'
                                        :
                                        user?.roles[0]
                            }
                        </div>
                    </div>
                    <BiSolidChevronDown
                        className='cursor-pointer'
                        color='white'
                        size={30}
                        onClick={() => setMenuOpen(!menuOpen)}
                    />
                    <div className={`select-menu top50 ${menuOpen && 'open'}`}>
                        <div
                            className='select-item'
                            onClick={() => {
                                setMenuOpen(false);
                            }}
                        >
                            Profile
                        </div>
                        <div className="select-item ">
                            <Link
                                style={{ fontWeight: 'normal' }}
                                className='none-decoration-link link'
                                to={{ pathname: '/settings' }}
                                onClick={() => {
                                    setMenuOpen(false);
                                }}
                            >
                                Settings
                            </Link>
                        </div>
                        <div style={{ width: "100%", height: 2, backgroundColor: "#DADADA" }} />
                        <div
                            className='select-item'
                            onClick={() => {
                                setMenuOpen(false);
                                handleLogout();
                            }}
                        >
                            Logout
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}