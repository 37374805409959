export const data = {
    todays_order: '59',
    net_revenue: '32,538',
    income_earned: '159,234',
    new_travelers: '1,103',
    top_hotels: [
        { name: 'Marriott Courtyard', revenue: '23.37' },
        { name: 'The Mozart Hotel', revenue: '34.35' },
        { name: 'Saint Médard', revenue: '19.92' },
        { name: 'Hollywood Hotel', revenue: '21.41' },
    ]
}

export const colors = [
    '#7856FF',
    '#FF7557',
    '#80E1D9',
    '#F8BC3B',
]