import React, { useState } from 'react';

import logoTextblack from '../../assets/logos/logo-text-black.svg';
// import ukFlag from '../../assets/icons/flags/uk.svg'

// import { MdArrowDropDown } from 'react-icons/md'
import { LoginForm } from './LoginForm';
import { ForgotPasswordFrom } from './ForgotPasswordForm';

// const languages = [
//     {
//         language: 'English',
//         country: 'United Kingdom',
//         flag: ukFlag
//     }
// ]

export const Login = ({ handleLogin, errorMessage }) => {
    const [activeForm, setActiveForm] = useState('login');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [language, setLanguage] = useState(languages[0]);
    // const [open, setOpen] = useState(false);

    // const handlaChangeLanguage = (lang) => {
    //     setLanguage(lang);
    //     setOpen(false);
    // }

    const handleForgotPassword = (email) => {
        console.log(email);
        alert(`Request sent to ${email}`)
        setActiveForm('login');
    }

    return (
        <div className='column-view h100vh items-center justify-between'>
            {/* <div className='mt20 mr20 self-end' style={{ position: "absolute" }}>
                <button
                    className={`row-view select-button justify-between items-center w250 ${!open && 'brdr8'}`}
                    onClick={() => setOpen(!open)}
                >
                    <img src={language.flag} alt={language.language} className='mr10' />
                    <div className='mr10'>{language.language} ({language.country})</div>
                    <MdArrowDropDown size={20} />
                </button>
                {open &&
                    <div className='dropdown-menu w250'>
                        {languages.map((lang, index) => {
                            return (
                                <button
                                    key={index}
                                    className='row-view dropdown-item items-center'
                                    onClick={() => handlaChangeLanguage(lang)}
                                >
                                    <img src={language.flag} alt={language.language} className='mr10' />
                                    <div className='mr10'>{language.language} ({language.country})</div>
                                </button>
                            )
                        })}
                    </div>
                }
            </div> */}
            <div className='column-view items-center flex06 mt20'>
                <img
                    src={logoTextblack}
                    alt='logoTextWhite'
                    className='logo'
                />
                <div className='title column-view text-center mt20'>
                    <span>
                        Luxury is matching the perfect customer
                    </span>
                    <span>
                        with a hotel for a specific trip
                    </span>
                </div>
            </div>
            <div className='flex1'>
                {activeForm === 'login' ?
                    <LoginForm
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        handleLogin={handleLogin}
                        setActiveForm={setActiveForm}
                        errorMessage={errorMessage}
                    />
                    :
                    <ForgotPasswordFrom
                        email={email}
                        setEmail={setEmail}
                        handleForgotPassword={handleForgotPassword}
                        setActiveForm={setActiveForm}
                    />
                }
            </div>
            <div className='column-view flex04 text-center justify-end pv20' >
                <div>
                    By continuing, you agree to the
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Terms of Service
                    </a>
                    {' '} and {' '}
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Privacy policy
                    </a>
                </div>
                <div className='mt20'>
                    All rights reserved.
                </div>
                <div>
                    ©Myhotelmatch {new Date().getFullYear()}
                </div>
            </div>
        </div>
    )
}