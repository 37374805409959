import React, { useEffect, useState } from "react";
import { getAllHotels } from "../../api/hotel.js";
import { useLocation, useNavigate } from "react-router-dom";
import { MdChevronLeft, MdClose } from "react-icons/md";
import {
  createHotel,
  getHotelContent,
  updateHotelContent,
} from "../../api/hotel";
import { TbFilterPlus } from "react-icons/tb";
import { MdSearch } from "react-icons/md";
import { BsPersonSquare } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HotelsAll } from "../../components/Hotels/HotelsAll.js";
import { InProgressHotels } from "../../components/Hotels/InProgressHotels.js";
import { NewHotels } from "../../components/Hotels/NewHotels.js";


export const Hotels = ({ handleLogout }) => {
  const [hotels, setHotels] = useState([]);
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState("All my hotels");
  const location = useLocation();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [hotelSub, setHotelSub] = useState(
    location.pathname.replace("/hotels/profile/", "")
  );
  const queryParams = new URLSearchParams(window.location.search)
  const [hotelsCount, setHotelsCount] = useState([]);
  const [infoModal, setInfoModal] = useState(false);
  const [allHotels, setAllHotels] = useState([]);
  const [inProgressHotels, setInProgressHotels] = useState([]);
  const [newHotels, setNewHotels] = useState([]);
  const [edit, setEdit] = useState(false);
  const [searchText, setSearchText] = useState(queryParams.get("search"));

  const handlePageClick = (type) => {
    setSelectedPage(type);
  };

  const handleSubmit = () => {
    updateHotelContent(hotel, hotelSub, (ok) => {
      if (ok) {
        navigate(-1);
      }
    });
  };

  //   useEffect(() => {
  //     if (isSearching) {
  //         navigate(`/hotels?page=${0}&limit=${hotelsPerPage}&search=${searchText}`)
  //         retrieveHotels(0, hotelsPerPage, searchText, response => {
  //             if (response?.status === 'success') {
  //                 setHotels(formatData(response?.content?.hotels))
  //             }
  //         })
  //     }
  // }, [searchText])

  const handleEdit = () => {
    if (edit) {
      // TODO API call to save updates
      setEdit(false);
    } else {
      setEdit(true);
    }
  };

  const [hotel, setHotel] = useState({
    name: "",
    showcase: {
      logo: null,
      banner: null,
    },
    address: "",
    zipcode: "",
    city: "",
    phone: "",
    presentation: "",
    overview: [null, null, null, null, null, null],
    year_of_creation: "",
    year_of_renovation: "",
    nb_rooms: "",
    labels: [],
    food_health: [],
    requirements: [],
    music_list: [],
    communities: [],
  });

  const formatData = (data) => {
    let res = [];
    data.map((h) => {
      return res.push({
        id: h?.hotel_sub,
        img: null,
        name: h?.name,
        owner: "___",
        dosm: "___",
        address: h?.address,
        zipcode: h?.zipcode,
        city: h?.city,
        website: h?.website,
      });
    });
    return res;
  };


  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (token) {

      const token_exp = JSON.parse(localStorage.getItem("token_exp"));
      if (token_exp < Date.now() / 1000) {
        handleLogout();
      }
    }
  }, [handleLogout]);



  const handleInputChange = (e) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);

  };

  const renderPage = () => {
    switch (selectedPage) {
      case "All my hotels":
        return <HotelsAll searchText={searchText ? searchText : ''} />;
      case "In progress":
        return <InProgressHotels />;
      case "New":
        return <NewHotels />;
      default:
        return null;
    }
  };

  return (
    <div className='row-view flex1 justify-center items-center'>
      <div
        className="page-container"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {infoModal && (
          <div
            className="filter-darker"
            onClick={() => {
              if (infoModal) {
                setInfoModal(false);
              }
            }}
          />
        )}
        <div className="row-view justify-between">
          <div>
            <div className="size2 bold">Hotels</div>
            <div className="size1 bold today">
              <span>{hotelsCount} hotels</span>
            </div>
          </div>
          <div className="row-view">
            <div className="row-view">
              <div className="secondary-search-bar-container row-view items-center justify-center">
                <div className="mr10">
                  <MdSearch size={20} />
                </div>
                <input
                  type='text'
                  value={searchText}
                  placeholder='Search'
                  className='secondary-search-bar'
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row-view justify-between ml10">
              <Link
                className="primary-button none-decoration-link row-view items-center"
                to={{ pathname: "/hotels/add" }}
              >
                <AiOutlinePlusCircle color="white" size={20} className="mr10" />
                <div>Add Hotel</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row-view items-center justify-between mt50">
          <div className="row-view ">
            <div
              className={`row-view items-center mr50 cursor-pointer pv4 ${selectedPage === "All my hotels" && "black-text bold bb161616"
                }`}
              onClick={() => handlePageClick("All my hotels")}
            >
              <div className={`mr5`}>All my hotels</div>
              {allHotels.length !== 0 && (
                <div className="notif">{allHotels.length}</div>
              )}
            </div>
            <div
              className={`row-view items-cente mr50 cursor-pointer pv4 ${selectedPage === "In progress" && "black-text bold bb161616"
                }`}
              onClick={() => handlePageClick("In progress")}
            >
              <div className={`mr5`}>In progress</div>
              {inProgressHotels.length !== 0 && (
                <div className="notif">{inProgressHotels.length}</div>
              )}
            </div>
            <div
              className={`row-view items-center mr50 cursor-pointer pv4 ${selectedPage === "New" && "black-text bold bb161616"
                }`}
              onClick={() => handlePageClick("New")}
            >
              <div className={`mr5`}>New</div>
              {newHotels.length !== 0 && (
                <div className="notif">{newHotels.length}</div>
              )}
            </div>
          </div>
        </div>

        <div className="">{renderPage()}</div>

      </div >
    </div>
  );
};
