const getApiUrl = () => {
    if (process.env.REACT_APP_ENV === "dev") {
        return "https://dev.api.mhmlabs.org";
    } else if (process.env.REACT_APP_ENV === "prod") {
        return "https://api.mhmlabs.org";
    } else {
        return "https://dev.api.mhmlabs.org";
    }
};



export var API_URL = getApiUrl();
export const SOCKET_URL = "wss://dev.real-time-chat.mhmlabs.org";
