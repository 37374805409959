import React, { useEffect, useState } from "react";

import addImg from "../../assets/icons/AddImg.svg";

import { MdChevronLeft, MdClose } from "react-icons/md";
import { BiSolidChevronDown } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createHotel,
  getHotelContent,
  updateHotelContent,
} from "../../api/hotel";
import { HotelDashboard } from "../../components/HotelProfile/HotelDashboard";

import { getUser } from "../../api/user";
import { HotelEdit } from "../../components/HotelProfile/HotelEdit";
import { HotelCalendar } from "../../components/HotelProfile/HotelCalendar";
import { HotelContacts } from "../../components/HotelProfile/HotelContacts";
import { HotelDocuments } from "../../components/HotelProfile/HotelDocuments";

const labels = ["Green Globe", "Green Tourism", "Earth Check"];

const requirements = [
  { name: "Parking", icon: "🚘" },
  { name: "Balcony", icon: "🪟" },
  { name: "View", icon: "🌄" },
  { name: "Silent room", icon: "🛌" },
  { name: "Smoking area", icon: "🔥" },
  { name: "Ground floor", icon: "🪜" },
];

const foodHealth = [
  { name: "Diabetic", icon: "💉" },
  { name: "Vegan", icon: "🌱" },
  { name: "ICD", icon: "🧰" },
  { name: "Gluten free", icon: "🌿" },
  { name: "Bug prevention", icon: "🪷" },
  { name: "Pork free", icon: "🐷" },
  { name: "Recent mattress", icon: "🛏️" },
  { name: "High-end mattress", icon: "🛌" },
  { name: "Seafood allergies", icon: "🦐" },
];

const communities = [
  { name: "LGBTQ+", icon: "🏳️‍🌈" },
  { name: "Cyclist", icon: "🚴‍♂️" },
  { name: "Biker", icon: "🛵" },
  { name: "Ecologist", icon: "🍀" },
  { name: "Pet lover", icon: "🐶" },
  { name: "Pork free", icon: "🐷" },
  { name: "Recent Feminism", icon: "🌸" },
  { name: "Cigar smokers", icon: "🛌" },
];

const musicList = [
  { title: "Beat It", artist: "Michael Jackson" },
  { title: "September", artist: "Earth, Wind & Fire" },
];

export const HotelProfile = ({ handleLogout }) => {
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState("Dashboard");
  const location = useLocation();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [hotelSub, setHotelSub] = useState(
    location.pathname.replace("/hotels/profile/", "")
  );
  const [infoModal, setInfoModal] = useState(false);
  const [dashboard, setDashboard] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [editHotel, setEditHotel] = useState([]);

  const handlePageClick = (type) => {
    setSelectedPage(type);
  };

  const [hotel, setHotel] = useState({
    name: "",
    showcase: {
      logo: null,
      banner: null,
    },
    address: "",
    zipcode: "",
    city: "",
    phone: "",
    presentation: "",
    overview: [null, null, null, null, null, null],
    year_of_creation: "",
    year_of_renovation: "",
    nb_rooms: "",
    labels: [],
    food_health: [],
    requirements: [],
    music_list: [],
    communities: [],
  });

  const handleSubmit = () => {
    updateHotelContent(hotel, hotelSub, (ok) => {
      if (ok) {
        navigate(-1);
      }
    });
  };

  const renderPage = () => {
    switch (selectedPage) {
      case "Dashboard":
        return <HotelDashboard />;
      case "Calendar":
        return <HotelCalendar />;
      case "Contacts":
        return <HotelContacts />;
      case "Documents":
        return <HotelDocuments />;
      case "Edit hotel":
        return <HotelEdit />;
      default:
        return null;
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    getHotelContent(hotelSub, (response) => {
      if (response.status === "success") {
        setHotel({ ...hotel, ...response.content });
      }
    });
    if (token) {
      const token_exp = JSON.parse(localStorage.getItem("token_exp"));
      if (token_exp < Date.now() / 1000) {
        handleLogout();
      }
    }
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLogout]);

  return (
    <div
      className="page-container"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {infoModal && (
        <div
          className="filter-darker"
          onClick={() => {
            if (infoModal) {
              setInfoModal(false);
            }
          }}
        />
      )}
      <div className="row-view items-center justify-between">
        <div className="row-view items-center">
          <MdChevronLeft
            className="cursor-pointer"
            size={50}
            onClick={() => navigate(-1)}
          />
          <div className="title-secondary">Add Hotel</div>
        </div>
        <div className="row-view ">
          <div
            className={`row-view items-center mr50 cursor-pointer pv4 ${
              selectedPage === "Dashboard" && "black-text bold bb161616"
            }`}
            onClick={() => handlePageClick("Dashboard")}
          >
            <div className={`mr5`}>Dashboard</div>
            {dashboard.length !== 0 && (
              <div className="notif">{dashboard.length}</div>
            )}
          </div>
          <div
            className={`row-view items-cente mr50 cursor-pointer pv4 ${
              selectedPage === "Calendar" && "black-text bold bb161616"
            }`}
            onClick={() => handlePageClick("Calendar")}
          >
            <div className={`mr5`}>Calendar</div>
            {calendar.length !== 0 && (
              <div className="notif">{calendar.length}</div>
            )}
          </div>
          <div
            className={`row-view items-center mr50 cursor-pointer pv4 ${
              selectedPage === "Contacts" && "black-text bold bb161616"
            }`}
            onClick={() => handlePageClick("Contacts")}
          >
            <div className={`mr5`}>Contacts</div>
            {contacts.length !== 0 && (
              <div className="notif">{contacts.length}</div>
            )}
          </div>
          <div
            className={`row-view items-center mr50 cursor-pointer pv4 ${
              selectedPage === "Documents" && "black-text bold bb161616"
            }`}
            onClick={() => handlePageClick("Documents")}
          >
            <div className={`mr5`}>Documents</div>
            {documents.length !== 0 && (
              <div className="notif">{documents.length}</div>
            )}
          </div>
          <div
            className={`row-view items-center mr50 cursor-pointer pv4 ${
              selectedPage === "Edit hotel" && "black-text bold bb161616"
            }`}
            onClick={() => handlePageClick("Edit hotel")}
          >
            <div className={`mr5`}>Edit hotel</div>
            {editHotel.length !== 0 && (
              <div className="notif">{editHotel.length}</div>
            )}
          </div>
        </div>
      </div>

      <div className="of-scroll">{renderPage()}</div>
    </div>
  );
};
