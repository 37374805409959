import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllHotels } from "../../api/hotel";

import { PiCaretUpDownLight } from "react-icons/pi";
import { Table } from "../../components/Table/Table.js";

export const InProgressHotels = ({ handleLogout }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [hotels, setHotels] = useState([]);
  const [edit, setEdit] = useState(false);
  const [currentPageData, setCurrentPageData] = useState(0);
  const navigate = useNavigate();

  const cells = [
    "Name",
    "Owner",
    "DOSM",
    "Address",
    "Zip code",
    "City",
    "Website",
  ];

  const formatData = (data) => {
    let res = [];
    data.map((h) => {
      return res.push({
        id: h?.hotel_sub,
        img: null,
        name: h?.name,
        owner:
          h?.owner.length !== 0
            ? `${h?.owner[0]?.firstname} ${h?.owner[0]?.lastname}`
            : "___",
        manager:
          h?.manager.length !== 0
            ? `${h?.manager[0]?.firstname} ${h?.manager[0]?.lastname}`
            : "___",
        address: h?.address,
        zipcode: h?.zipcode,
        city: h?.city,
        website: h?.website,
      });
    });
    return res;
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token");
    if (token) {
      getAllHotels((response) => {
        if (response.status === "success") {
          setHotels(formatData(response.content));
          //setInProgressHotels(formatData(response.content));
        }
      });

      const token_exp = JSON.parse(localStorage.getItem("token_exp"));
      if (token_exp < Date.now() / 1000) {
        handleLogout();
      }
    }
  }, [handleLogout]);

  return (
    <div className="page-container">
      <div className="mt20 of-scroll">
        <Table cells={cells} data={hotels} type="hotels" edit={edit} />
      </div>

      <div className="row-view items-center justify-between mt50 ">
        <div className="row-view items-center">
          <button className="secondary-button row-view p10 mr10">
            <div className="mr5">25</div>
            <PiCaretUpDownLight />
          </button>
          <div className="section-title">hotels per page</div>
        </div>
      </div>
    </div>
  );
};
