/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { BiSolidChevronDown } from 'react-icons/bi'
import { FiEdit, FiEye, FiEyeOff, FiTrash2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { MdAccountCircle } from 'react-icons/md';
import { FaHotel } from 'react-icons/fa';
import hotelIcon from '../../assets/icons/Hotel.svg'

const roles = ['Manager', 'Accountant', 'Staff']

const handleSelect = (key, value) => {
  // setStaff({ ...staff, [key]: [value] });
}

export const Table = ({ cells, data, type, edit, currentPage }) => {
  const [rolesOpen, setRolesOpen] = useState(false)
  const [sub, setSub] = useState(null)
  const [renderCells, setRenderCells] = useState([])
  const [renderData, setRenderData] = useState([])

  useEffect(() => {
    setRenderCells(cells)
    setRenderData(data)
    if (type === 'hotels') {
      if (edit) {
        setRenderCells([...cells, ''])
        let arr = renderData
        arr.map((el) => {
          el['edit'] = ''
        })
        setRenderData(arr)
      } else {
        setRenderCells(cells)
        setRenderData(data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells, data, edit, type])

  return (
    <div style={{ border: '1px solid #F6F6F6', borderRadius: 8 }}>
      <table className="table">
        <thead>
          <tr>
            {renderCells.map((cell, index) => {
              return <th key={index}>{cell}</th>
            })}
          </tr>
        </thead>
        {typeof (renderData) === 'object' ?
          <tbody>
            {renderData.map((el, idx) => (
              <tr key={idx} style={type === 'hotels' && !el?.active ? { backgroundColor: '#F6F6F6' } : { backgroundColor: '#FFFFFF' }}>
                {Object.entries(el).map(([key, value], index) => {
                  if (key === 'img') {
                    switch (type) {
                      case 'reservations':
                        return (
                          <td key={index} className='tr'>
                            <Link
                              className="row-view items-center table-link black-text p4"
                              to={`/travelers/profile/${el.user_sub}`}
                              state={{ currentPage: currentPage ? currentPage : 0 }}
                            >
                              {value !== null ?
                                <img
                                  src={value}
                                  alt="img"
                                  className="r100 mr10"
                                  style={{ width: 30 }}
                                ></img>
                                :
                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                              }
                              {el.name}
                            </Link>
                          </td>
                        )
                      case 'hotels':
                        return (
                          <td key={index} className='tr'>
                            <Link
                              className="row-view items-center table-link black-text p4"
                              to={`/hotels/profile/${el.id}`}
                            >
                              {value !== null ?
                                <img
                                  src={value}
                                  alt="img"
                                  className="r100 mr10"
                                  style={{ width: 30 }}
                                ></img>
                                :
                                <img
                                  src={hotelIcon}
                                  alt="hotelIcon"
                                  className="mr10"
                                  style={{ width: 30 }}
                                ></img>
                              }
                              {el.name}
                            </Link>
                          </td>
                        )
                      case 'staff':
                        return (
                          <td key={index} className='tr'>
                            <Link
                              className="row-view items-center table-link black-text p4"
                              to={`/staff/profile/${el.id}`}
                              state={{ role: el.role }}
                            >
                              {value !== null ?
                                <img
                                  src={value}
                                  alt="img"
                                  className="r100 mr10"
                                ></img>
                                :
                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                              }
                              {el.name}
                            </Link>
                          </td>
                        )
                      case 'contact':
                        return (
                          <td key={index} className='tr'>
                            <Link
                              className="row-view items-center table-link black-text p4"
                              to={`/contact/profile/${el.id}`}
                              state={{ role: el.role }}
                            >
                              {value !== null ?
                                <img
                                  src={value}
                                  alt="img"
                                  className="r100 mr10"
                                ></img>
                                :
                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                              }
                              {el.name}
                            </Link>
                          </td>
                        )
                      case 'travelers':
                        return (
                          <td key={index} className='tr'>
                            <Link
                              className="row-view items-center table-link black-text p4"
                              to={`/travelers/profile/${el.id}`}
                            >
                              {value !== null ?
                                <img
                                  src={value}
                                  alt="img"
                                  className="r100 mr10"
                                ></img>
                                :
                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                              }
                              {el.name}
                            </Link>
                          </td>
                        )
                      case 'profiles':
                        return (
                          <td key={index} className='tr'>
                            <Link
                              className="row-view items-center table-link black-text p4"
                              to={`/profiles/profile/${el.id}`}
                            >
                              {value !== null ?
                                <img
                                  src={value}
                                  alt="img"
                                  className="r100 mr10"
                                  style={{ width: 30 }}
                                ></img>
                                :
                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                              }
                              {el.name}
                            </Link>
                          </td>
                        )
                      default:
                        return (
                          <td
                            className="row-view items-center table-link black-text tr"
                            key={index}
                          >
                            <div className="p4">
                              {value !== null && (
                                <img
                                  src={value}
                                  alt="img"
                                  className="r100 mr10"
                                ></img>
                              )}
                              {el.name}
                            </div>
                          </td>
                        )
                    }
                  } else if (key !== 'name' && key !== 'id' && key !== 'active') {
                    if (typeof value === 'boolean') {
                      return (
                        <td key={index}>
                          {value ? (
                            <AiOutlineCheckCircle
                              size={20}
                              color="#5E9A37"
                              className="p4"
                            />
                          ) : (
                            <AiOutlineCloseCircle
                              size={20}
                              color="#E84E48"
                              className="p4"
                            />
                          )}
                        </td>
                      )
                    } else {
                      switch (type) {
                        case 'reservations':
                          if (key === 'reservationId') {
                            return (
                              <td key={index}>
                                <Link
                                  className="table-link p4"
                                  to={`/reservations/${el.id}`}
                                >
                                  {value.split('-')[1] + '-' + value.split('-')[2]}
                                </Link>
                              </td>
                            )
                          } else if (key === 'state') {
                            switch (value) {
                              case 'succeeded':
                                return (
                                  <td key={index}>
                                    <div className="green-text2 bold p4">
                                      {value?.[0]?.toUpperCase()}{value.slice(1)}
                                    </div>
                                  </td>
                                )
                              // case 'Pending':
                              //     return (
                              //         <td key={index}>
                              //             <div className="orange-text bold p4">
                              //                 {value}
                              //             </div>
                              //         </td>
                              //     )
                              case 'canceled':
                                return (
                                  <td key={index}>
                                    <div className="red-text bold p4">
                                      {value?.[0]?.toUpperCase()}{value.slice(1)}
                                    </div>
                                  </td>
                                )
                              default:
                                return (
                                  <td key={index}>
                                    <div className="orange-text bold p4">
                                      {value?.[0]?.toUpperCase()}{value.slice(1)}
                                    </div>
                                  </td>
                                )
                            }
                          } else {
                            return (
                              <td key={index}>
                                <div className="p4">
                                  {key !== 'user_sub' && value}
                                </div>
                              </td>
                            )
                          }
                        case 'hotels':
                          if (key === 'website') {
                            return (
                              <td key={index} className='of-ellipsis' style={{ maxWidth: 200 }}>
                                {value !== '___' ?
                                  <Link
                                    className="table-link p4"
                                    to={`${value}`}
                                    target="_blank"
                                  >
                                    {value}
                                  </Link>
                                  :
                                  <div
                                    className="p4"
                                  >
                                    {value}
                                  </div>
                                }
                              </td>
                            )
                          } else if (
                            key === 'edit' &&
                            edit
                          ) {
                            return (
                              <td key={index}>
                                <div className="row-view justify-evenly items-center p4 r8 bg428C78">
                                  <FiEdit
                                    size={20}
                                    color="white"
                                    className="cursor-pointer"
                                  />
                                  <div
                                    style={{
                                      width: 2,
                                      backgroundColor:
                                        'white',
                                      height: 15,
                                    }}
                                  />
                                  {idx % 2 !==
                                    0 ? (
                                    <FiEyeOff
                                      size={
                                        20
                                      }
                                      color="white"
                                      className="cursor-pointer"
                                    />
                                  ) : (
                                    <FiEye
                                      size={
                                        20
                                      }
                                      color="white"
                                      className="cursor-pointer"
                                    />
                                  )}
                                  <div
                                    style={{
                                      width: 2,
                                      backgroundColor:
                                        'white',
                                      height: 15,
                                    }}
                                  />
                                  <FiTrash2
                                    size={20}
                                    color="white"
                                    className="cursor-pointer"
                                  />
                                </div>
                              </td>
                            )
                          } else {
                            return (
                              <td key={index}>
                                <div className="p4">
                                  {value}
                                </div>
                              </td>
                            )
                          }
                        case 'staff':
                          if (key === 'email') {
                            return (
                              <td key={index}>
                                <Link
                                  className="table-link p4"
                                  to={`mailto:${value}`}
                                  target="_blank"
                                >
                                  {value}
                                </Link>
                              </td>
                            )
                          } else if (
                            key === 'role' &&
                            edit
                          ) {
                            return (
                              <td key={index}>
                                <div className="relative">
                                  <div
                                    className="border-container bg428C78 white-text row-view items-center justify-between w120 p4 r8 cursor-pointer"
                                    onClick={() => {
                                      setRolesOpen(
                                        !rolesOpen
                                      )
                                      setSub(
                                        el.id
                                      )
                                    }}
                                  >
                                    <div>
                                      {value
                                        ? value
                                        : 'Select role'}
                                    </div>
                                    <BiSolidChevronDown />
                                  </div>
                                  <div
                                    className={`select-menu ${rolesOpen &&
                                      el.id ===
                                      sub &&
                                      'open'
                                      }`}
                                  >
                                    {roles.length !==
                                      0 ? (
                                      <>
                                        {roles.map(
                                          (
                                            role,
                                            idx
                                          ) => {
                                            return (
                                              <div
                                                key={
                                                  idx
                                                }
                                                className="select-item"
                                                onClick={() => {
                                                  handleSelect(
                                                    'role',
                                                    role
                                                  )
                                                  setRolesOpen(
                                                    false
                                                  )
                                                }}
                                              >
                                                <span className="section-title">
                                                  {
                                                    role
                                                  }
                                                </span>
                                              </div>
                                            )
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="select-item">
                                          No
                                          roles
                                          found
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                            )
                          } else if (key === 'role') {
                            return (
                              <td key={index}>
                                <div className="p4">
                                  {value[0]?.toUpperCase() + value?.slice(1)}
                                </div>
                              </td>
                            )
                          } else {
                            return (
                              <td key={index}>
                                <div className="p4">
                                  {value}
                                </div>
                              </td>
                            )
                          }
                        case 'travelers':
                          if (key === 'email') {
                            return (
                              <td key={index}>
                                <Link
                                  className="table-link p4"
                                  to={`mailto:${value}`}
                                  target="_blank"
                                >
                                  {value}
                                </Link>
                              </td>
                            )
                          } else {
                            return (
                              <td key={index}>
                                <div className="p4">
                                  {value}
                                </div>
                              </td>
                            )
                          }
                        default:
                          return (
                            <td key={index}>
                              <div className="p4">
                                {value}
                              </div>
                            </td>
                          )
                      }
                    }
                  }
                })}
              </tr>
            ))}
          </tbody>
          :
          <div className='p10'>{renderData}</div>
        }
      </table>
    </div>
  )
}
