import React from "react";
import ReactDOM from "react-dom";
import staffSvg from "../../assets/icons/Staff.svg";

export const CheckCalendar = ({
  arrayStaff,
  checkInColor,
  checkOutColor,
  date,
  countStart,
  countEnd,
  fontCheckInColor,
  fontCheckOutColor,
}) => {
  const tdElement = document.querySelector(`td[data-date="${date}"]`);

  let isIn = false;

  if (arrayStaff[date]) {
    isIn = true;
  }

  if (tdElement) {
    const childDiv = tdElement.querySelector("div");

    if (childDiv.childElementCount === 3) {
      if (
        countStart &&
        countEnd &&
        !isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {
        const element = (
          <div>
            <div
              className="row-view justify-center items-center"
              style={{
                backgroundColor: checkInColor,
                position: "absolute",
                bottom: 0,
                borderRadius: 25,
                width: 20,
                height: 20,
                fontSize: 12,
                marginLeft: 5,
                marginBottom: 5,
                color: fontCheckInColor,
              }}
            >
              {countStart}
            </div>

            <div
              className="row-view justify-center items-center"
              style={{
                backgroundColor: checkOutColor,
                position: "absolute",
                bottom: 0,
                left: 25,
                borderRadius: 25,
                width: 20,
                height: 20,
                fontSize: 12,
                marginLeft: 5,
                marginBottom: 5,
                color: fontCheckOutColor,
              }}
            >
              {countEnd}
            </div>
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (
        countStart &&
        !countEnd &&
        !isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {

        const element = (
          <div
            className="row-view justify-center items-center"
            style={{
              backgroundColor: checkInColor,
              position: "absolute",
              bottom: 0,
              borderRadius: 25,
              width: 20,
              height: 20,
              fontSize: 12,
              marginLeft: 5,
              marginBottom: 5,
              color: fontCheckInColor,
            }}
          >
            {countStart}
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (
        !countStart &&
        countEnd &&
        !isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {
        const element = (
          <div
            className="row-view justify-center items-center"
            style={{
              backgroundColor: checkOutColor,
              position: "absolute",
              bottom: 0,
              borderRadius: 25,
              width: 20,
              height: 20,
              fontSize: 12,
              marginLeft: 5,
              marginBottom: 5,
              color: fontCheckOutColor,
            }}
          >
            {countEnd}
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (
        countStart &&
        countEnd &&
        isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {
        const element = (
          <div>
            <div
              className="row-view justify-center items-center"
              style={{
                backgroundColor: checkInColor,
                position: "absolute",
                bottom: 0,
                borderRadius: 25,
                width: 20,
                height: 20,
                fontSize: 12,
                marginLeft: 5,
                marginBottom: 5,
                color: fontCheckInColor,
              }}
            >
              {countStart}
            </div>

            <div
              className="row-view justify-center items-center"
              style={{
                backgroundColor: checkOutColor,
                position: "absolute",
                bottom: 0,
                left: 25,
                borderRadius: 25,
                width: 20,
                height: 20,
                fontSize: 12,
                marginLeft: 5,
                marginBottom: 5,
                color: fontCheckOutColor,
              }}
            >
              {countEnd}
            </div>
            <div
              className="row-view justify-center items-center"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 20,
                height: 20,
                fontSize: 10,
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <div className="mr5">
                <img src={staffSvg} alt="user" className="icon" />
              </div>
            </div>
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (
        countStart &&
        !countEnd &&
        isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {
        const element = (
          <div>
            <div
              className="row-view justify-center items-center"
              style={{
                backgroundColor: checkInColor,
                position: "absolute",
                bottom: 0,
                borderRadius: 25,
                width: 20,
                height: 20,
                fontSize: 12,
                marginLeft: 5,
                marginBottom: 5,
                color: fontCheckInColor,
              }}
            >
              {countStart}
            </div>

            <div
              className="row-view justify-center items-center"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 20,
                height: 20,
                fontSize: 10,
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <div className="mr5">
                <img src={staffSvg} alt="user" className="icon" />
              </div>
            </div>
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (
        !countStart &&
        countEnd &&
        isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {
        const element = (
          <div>
            <div
              className="row-view justify-center items-center"
              style={{
                backgroundColor: checkOutColor,
                position: "absolute",
                bottom: 0,
                borderRadius: 25,
                width: 20,
                height: 20,
                fontSize: 12,
                marginLeft: 5,
                marginBottom: 5,
                color: fontCheckOutColor,
              }}
            >
              {countEnd}
            </div>

            <div
              className="row-view justify-center items-center"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 20,
                height: 20,
                fontSize: 10,
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <div className="mr5">
                <img src={staffSvg} alt="user" className="icon" />
              </div>
            </div>
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (
        countStart &&
        !countEnd &&
        isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {
        const element = (
          <div>
            <div
              className="row-view justify-center items-center"
              style={{
                backgroundColor: checkInColor,
                position: "absolute",
                bottom: 0,
                borderRadius: 25,
                width: 20,
                height: 20,
                fontSize: 12,
                marginLeft: 5,
                marginBottom: 5,
                color: fontCheckInColor,
              }}
            >
              {countStart}
            </div>

            <div
              className="row-view justify-center items-center"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 20,
                height: 20,
                fontSize: 10,
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <div className="mr5">
                <img src={staffSvg} alt="user" className="icon" />
              </div>
            </div>
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (
        !countStart &&
        !countEnd &&
        isIn &&
        countStart !== -1 &&
        countEnd !== -1
      ) {
        const element = (
          <div>
            <div
              className="row-view justify-center items-center"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 20,
                height: 20,
                fontSize: 10,
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <div className="mr5">
                <img src={staffSvg} alt="user" className="icon" />
              </div>
            </div>
          </div>
        );
        ReactDOM.render(element, childDiv);
      } else if (countStart === -1 && countEnd === -1) {
        const element = (
          <div>
            <div
              className="row-view justify-center items-center"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 20,
                height: 20,
                fontSize: 10,
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <div className="mr5">
                <img src={staffSvg} alt="user" className="icon" />
              </div>
            </div>
          </div>
        );
        ReactDOM.render(element, childDiv);
      }
    }

    // Utilise ReactDOM pour rendre l'élément JSX React dans divElement
  }
};
