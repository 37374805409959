import { API_URL } from "./const";

const getToken = async () => {
  return JSON.parse(localStorage.getItem("auth_token"));
};

export const getAllManager = async (callback) => {
  const authToken = await getToken();
  fetch(`${process.env.REACT_APP_API_URL}/hotel/manager`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getAllOwner = async (callback) => {
  const authToken = await getToken();
  fetch(`${process.env.REACT_APP_API_URL}/hotel/owner`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getAllStaff = async (callback) => {
  const authToken = await getToken();
  fetch(`${process.env.REACT_APP_API_URL}/hotel/staff`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getAllContacts = async () => {
  try {
    const [owners, managers, staff] = await Promise.all([
      new Promise((resolve, reject) => {
        getAllOwner(resolve);
      }),
      new Promise((resolve, reject) => {
        getAllManager(resolve);
      }),
      new Promise((resolve, reject) => {
        getAllStaff(resolve);
      })
    ]);

    const allContacts = {
      owners: owners,
      managers: managers,
      staff: staff
    };

    return allContacts;
  } catch (error) {
    console.error(error);
  }
};

async function makeAPICall(url) {
  const authToken = await getToken();

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      method: "GET",
    });

    if (!response.ok) {
      console.error(response);
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getContactContent = async (contactSub, callback) => {

  // Premier appel API
  makeAPICall(`${API_URL}/hotel/owner/${contactSub}`)
    .then((data) => {
      if (data) {
        console.debug(data);
        callback(data);
      } else {
        // Deuxième appel API si le premier n'a pas renvoyé de données
        return makeAPICall(`${API_URL}/hotel/manager/${contactSub}`);
      }
    })
    .then((data) => {
      if (data) {
        console.debug(data);
        callback(data);
      } else {
        // Troisième appel API si ni le premier ni le deuxième n'ont renvoyé de données
        return makeAPICall(`${API_URL}/hotel/owner/${contactSub}`);
      }
    })
    .then((data) => {
      if (data) {
        console.debug(data);
        callback(data);
      }
    });

}