export const colors = ["#00BA34", "#E92C2C", "#0085FF", "#FF9F2D"];
export const fontColors = ["#00BA34", "#E92C2C", "#0085FF", "#FF9F2D"];

export const eventsData = [
  {
    id: 0,
    title: "Reservation test",
    start: new Date(),
    end: new Date(2023, 7, 1, 23, 59, 0),
    tag: "reservation",
  },
  {
    id: 1,
    title: "Harry Cover",
    start: new Date(2023, 6, 31, 0, 0, 0),
    end: new Date(2023, 7, 5, 23, 59, 0),
    tag: "traveler",
  },

  {
    id: 2,
    title: "Business",
    start: new Date(2023, 6, 30, 0, 0, 0),
    end: new Date(2023, 6, 30, 0, 0, 0),

    tag: "mood",
  },
  {
    id: 3,
    title: "Event",
    start: new Date(2023, 7, 6, 0, 0, 0),
    end: new Date(2023, 7, 12, 23, 59, 0),
    tag: "event",
  },
  {
    id: 4,
    title: "Reservation",
    start: new Date(2023, 7, 6, 0, 0, 0),
    end: new Date(2023, 7, 8, 0, 0, 0),
    tag: "reservation",
  },
  {
    id: 5,
    title: "Reservation",
    start: new Date(2023, 7, 10, 0, 0, 0),
    end: new Date(2023, 7, 12, 23, 59, 0),
    tag: "reservation",
  },
  {
    id: 6,
    title: "Reservation",
    start: new Date(2023, 7, 27, 0, 0, 0),
    end: new Date(2023, 7, 28, 23, 59, 0),
    tag: "reservation",
  },
  {
    id: 7,
    title: "Jarry",
    start: new Date(2023, 7, 14, 0, 0, 0),
    end: new Date(2023, 7, 19, 23, 59, 0),
    tag: "traveler",
  },
  {
    id: 8,
    title: "Mood Family Profile",
    start: new Date(2023, 7, 13, 0, 0, 0),
    end: new Date(2023, 7, 17, 23, 59, 0),
    tag: "mood",
  },
  {
    id: 9,
    title: "Reservation",
    start: new Date(2023, 9, 2, 0, 0, 0),
    end: new Date(2023, 9, 4, 23, 59, 0),
    tag: "reservation",
  },
  {
    id: 10,
    title: "Mood Business Profile",
    start: new Date(2023, 7, 20, 0, 0, 0),
    end: new Date(2023, 7, 26, 23, 59, 0),
    tag: "mood",
  },
  {
    id: 11,
    title: "Event",
    start: new Date(2023, 7, 27, 0, 0, 0),
    end: new Date(2023, 7, 30, 23, 59, 0),
    tag: "event",
  },
  {
    id: 12,
    title: "Reservation",
    start: new Date(2023, 8, 27, 0, 0, 0),
    end: new Date(2023, 9, 2, 23, 59, 0),
    tag: "reservation",
  },
  {
    id: 13,
    title: "Vacances Mathis",
    start: new Date(2023, 8, 27, 0, 0, 0),
    end: new Date(2023, 9, 4, 23, 59, 0),
    tag: "staff",
  },
];
