export const ticketsData = [
    {
        author: 'Stefan Radstrom',
        date: 'Jul 31, 2023',
        title: 'Update Hotels problems',
        comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...',
        severity: 'High'
    },
    {
        author: 'Stefan Radstrom',
        date: 'Jul 27, 2023',
        title: 'Refresh inbox',
        comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...',
        severity: 'Medium'
    },
    {
        author: 'Stefan Radstrom',
        date: 'Jul 21, 2023',
        title: 'Revenue view',
        comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...',
        severity: 'Low'
    },
]