import React from 'react';
import { Link } from 'react-router-dom';

export const LoginForm = ({ email, setEmail, password, setPassword, handleLogin, setActiveForm, errorMessage }) => {
    return (
        <form
            className='login-container'
            onSubmit={(e) => {
                e.preventDefault()
                handleLogin(email, password);
            }}
        >
            <div>Email address</div>
            <input
                className='input-text mt10'
                type='email'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className='mt10'>Password</div>
            <input
                className='input-text mt10'
                type='password'
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
            />

            <button
                type='submit'
                className='primary-button mt20'
            >
                Login
            </button>
            {errorMessage !== '' && <div className='error-message mt10'>{errorMessage}</div>}
            <Link className='mt20 link none-decoration-link text-center' onClick={() => setActiveForm('forgot')}>
                Forgot your password?
            </Link>
        </form>
    )
}