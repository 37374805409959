import React, { useEffect, useState } from "react";

import addImg from "../../assets/icons/AddImg.svg";

import { MdChevronLeft, MdClose, MdCheck } from "react-icons/md";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { BiSolidChevronDown } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { getContactContent } from "../../api/contact";

const nationalities = [
    { name: "France", icon: "🇫🇷" },
    { name: "UK", icon: "🇬🇧" },
    { name: "Italy", icon: "🇮🇹" },
    { name: "US", icon: "🇺🇸" },
    { name: "German", icon: "🇩🇪" },
    { name: "Spanish", icon: "🇪🇸" },
];

const locations = [
    { city: "Cannes", country: "France" },
    { city: "Paris", country: "France" },
    { city: "Prague", country: "Czech" },
];

export const ContactProfile = ({ handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [infoModal, setInfoModal] = useState(false);
    const [nationsOpen, setNationsOpen] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [locationOpen, setLocationsOpen] = useState(false);
    const [entityOpen, setEntityOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [contactSub, setHotelSub] = useState(
        location.pathname.replace("/contact/profile/", "")
    );

    const handleCheckboxAccess = (option) => {
        if (selectedOption === option) {
            setSelectedOption(null);
        } else {
            setSelectedOption(option);
        }
    };

    const [contact, setContact] = useState({
        firstname: "",
        lastname: "",
        position: "",
        nationality: [],
        roles: [],
        available: false,
        entity: [],
        locations: [],
        email: "",
        phone: "",
        access: false,
    });

    const hiddenFileInput = React.useRef(null);

    const handlePhoto = () => {
        hiddenFileInput.current.click();
    };

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    if (img.width >= 150 && img.height >= 150) {
                        setContact({ ...contact, photo: e.target.result });
                    } else {
                        alert("Image size must be at least 150x150 pixels ");
                    }
                };
            };

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleCheckbox = (value) => {
        if (contact.roles.includes(value)) {
            setContact({
                ...contact,
                roles: contact.roles.filter((el) => el !== value),
            });
        } else {
            setContact({ ...contact, roles: [...contact.roles, value] });
        }
    };

    const handleInputText = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };

    const handleSelect = (key, value) => {
        setContact({ ...contact, [key]: [value] });
    };

    const handleSubmit = () => { };

    useEffect(() => {
        let token = localStorage.getItem("auth_token");
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem("token_exp"));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }
        }
        getContactContent(contactSub, (response) => {
            if (response.status === "success") {
                setContact({ ...contact, ...response.content });
            }
        });
    }, [handleLogout]);

    console.log(contact);

    return (
        <form
            className={`page-container`}
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            {infoModal && (
                <div
                    className="filter-darker"
                    onClick={() => {
                        if (infoModal) {
                            setInfoModal(false);
                        }
                    }}
                />
            )}
            <div className="row-view items-center">
                <MdChevronLeft size={50} onClick={() => navigate(-1)} />
                <div className="title-secondary">Add Contact</div>
            </div>

            <div className="subtitle mt50 ml50">General information*</div>
            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">First name*</div>
                    <div className="row-view flex1 items-center">
                        <input
                            className="input-text w300 ph20"
                            name="firstname"
                            placeholder="First name..."
                            value={contact?.firstname}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Last name*</div>
                    <div className="row-view flex1 items-center">
                        <input
                            className="input-text w300 ph20"
                            name="lastname"
                            placeholder="Last name..."
                            value={contact?.lastname}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Position*</div>
                    <div className="row-view flex1 items-center">
                        <input
                            className="input-text w300 ph20"
                            name="lastname"
                            placeholder="Position..."
                            value={contact?.position}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Nationality*</div>
                    <div className="row-view flex1 items-center cursor-pointer">
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20"
                                onClick={() => setNationsOpen(!nationsOpen)}
                            >
                                <div>
                                    {contact?.nationality?.length
                                        ? `${contact?.nationality[0].name} - ${contact?.nationality[0].icon}`
                                        : "Select nationality"}
                                </div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${nationsOpen && "open"}`}>
                                {nationalities?.length !== 0 ? (
                                    <>
                                        {nationalities.map((nation, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect("nationality", nation);
                                                        setNationsOpen(false);
                                                    }}
                                                >
                                                    <span className="section-title">{nation.name}</span> -{" "}
                                                    {nation.icon}
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">No nationality found</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Photo*</div>
                    <div className="row-view flex1 items-center">
                        <div className="row-view items-center">
                            {!contact?.photo ? (
                                <div
                                    className="border-container b-dashed b2 r8 p20 mr20"
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img src={addImg} alt="addImg" />
                                </div>
                            ) : (
                                <div
                                    className="p20 mr20 relative"
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img
                                        className="r8"
                                        src={contact?.photo}
                                        alt="staffPhoto"
                                        style={{
                                            width: "60px",
                                            height: "60px",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <div
                                        className="close-icon"
                                        onClick={() => setContact({ ...contact, photo: null })}
                                    >
                                        <MdClose size={20} color="white" />
                                    </div>
                                </div>
                            )}
                            <div className="section-subtitle">
                                (JPGs or PNGs of at least 150x150px)
                            </div>
                        </div>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputPhoto(e)}
                            style={{ display: "none" }}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Roles*</div>
                    <div className="row-view flex1">
                        <div className="column-view justify-start">
                            <div
                                className="bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer"
                                onClick={() => handleCheckbox("Owner")}
                            >
                                <div
                                    className={`checkbox r4 mr10 ${contact?.roles &&
                                        contact?.roles?.includes("Owner") &&
                                        "selected"
                                        }`}
                                >
                                    {contact?.roles?.includes("Owner") && (
                                        <MdCheck color="white" />
                                    )}
                                </div>
                                <div>Owner</div>
                            </div>
                            <div
                                className="bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer"
                                onClick={() => handleCheckbox("Compliance")}
                            >
                                <div
                                    className={`checkbox r4 mr10 ${contact?.roles &&
                                        contact?.roles?.includes("Compliance") &&
                                        "selected"
                                        }`}
                                >
                                    {contact?.roles?.includes("Compliance") && (
                                        <MdCheck color="white" />
                                    )}
                                </div>
                                <div>Compliance</div>
                            </div>
                            <div
                                className="bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer"
                                onClick={() => handleCheckbox("Manager")}
                            >
                                <div
                                    className={`checkbox r4 mr10 ${contact?.roles &&
                                        contact?.roles?.includes("Manager") &&
                                        "selected"
                                        }`}
                                >
                                    {contact?.roles?.includes("Manager") && (
                                        <MdCheck color="white" />
                                    )}
                                </div>
                                <div>Manager</div>
                            </div>
                        </div>
                        <div className="column-view justify-start ml10">
                            <div
                                className="bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer"
                                onClick={() => handleCheckbox("Commercial")}
                            >
                                <div
                                    className={`checkbox r4 mr10 ${contact?.roles &&
                                        contact?.roles?.includes("Commercial") &&
                                        "selected"
                                        }`}
                                >
                                    {contact?.roles?.includes("Commercial") && (
                                        <MdCheck color="white" />
                                    )}
                                </div>
                                <div>Commercial</div>
                            </div>
                            <div
                                className="bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer"
                                onClick={() => handleCheckbox("Support")}
                            >
                                <div
                                    className={`checkbox r4 mr10 ${contact?.roles &&
                                        contact?.roles?.includes("Support") &&
                                        "selected"
                                        }`}
                                >
                                    {contact?.roles?.includes("Support") && (
                                        <MdCheck color="white" />
                                    )}
                                </div>
                                <div>Support</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Availability*</div>
                    <div className="row-view flex1 items-center">
                        <div className="row-view items-center w300">
                            <div
                                className={`toggle mr10 ${contact?.available && "checked"}`}
                                onClick={() =>
                                    setContact({ ...contact, available: !contact?.available })
                                }
                            >
                                <div
                                    className={`toggle-span ${contact?.available && "checked"}`}
                                />
                            </div>
                            <div className="section-title mr10">Available</div>
                            <div className="relative row-view items-center">
                                <BsFillQuestionCircleFill
                                    color="#428C78"
                                    size={20}
                                    onClick={() => setInfoModal(!infoModal)}
                                />
                                <div className={`info-modal ${infoModal && "active"}`}>
                                    Is this hotelier unavailable or on vacation? Update its status
                                    to <span className="green-text bold">"not available"</span> to
                                    notify every member. The hotelier will be{" "}
                                    <span className="green-text bold">
                                        hidden in the application
                                    </span>{" "}
                                    during this period.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Entity linked*</div>
                    <div className="row-view flex1 items-center">
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20"
                                onClick={() => setEntityOpen(!entityOpen)}
                            >
                                <div>
                                    {contact?.entity?.length
                                        ? `${contact?.entity[0].name} - ${contact?.entity[0].city}`
                                        : "Select hotel"}
                                </div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${entityOpen && "open"}`}>
                                {hotels?.length !== 0 ? (
                                    <>
                                        {hotels.map((hotel, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect("entity", hotel);
                                                        setEntityOpen(false);
                                                    }}
                                                >
                                                    <span className="section-title">{hotel.name}</span> -{" "}
                                                    {hotel.city}
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">No hotels found</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Email address*</div>
                    <div className="row-view flex1 items-center">
                        <input
                            className="input-text w300 ph20"
                            name="email"
                            placeholder="Email address..."
                            value={contact?.email}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Phone number*</div>
                    <div className="row-view flex1 items-center">
                        <input
                            className="input-text w300 ph20"
                            name="phone"
                            placeholder="Phone number..."
                            value={contact?.phone}
                            onChange={(e) => handleInputText(e)}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Access to BO*</div>

                    <div className="column-view flex1">
                        <div
                            className={`bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer ${selectedOption === "Yes" && "selected"
                                }`}
                            onClick={() => handleCheckboxAccess("Yes")}
                        >
                            <div
                                className={`checkbox-round r4 mr10 ${selectedOption === "Yes" && "selected"
                                    }`}
                            >
                                {selectedOption === "Yes" && <MdCheck color="white" />}
                            </div>
                            <div>Yes</div>
                        </div>
                        <div
                            className={`bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer mt10 ${selectedOption === "No" && "selected"
                                }`}
                            onClick={() => handleCheckboxAccess("No")}
                        >
                            <div
                                className={`checkbox-round r4 mr10 ${selectedOption === "No" && "selected"
                                    }`}
                            >
                                {selectedOption === "No" && <MdCheck color="white" />}
                            </div>
                            <div>No</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row-view justify-end mt20">
                <button
                    className="secondary-button mr20"
                    type="reset"
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </button>
                <button className="primary-button" type="submit">
                    Create Contact
                </button>
            </div>
        </form>
    );
};
