import React, { useEffect, useState } from 'react'
import arrowLeft from '../../assets/icons/arrow-left.svg'
import arrowRight from '../../assets/icons/arrow-right.svg'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { PiCaretUpDownLight } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import { TicketCard } from '../../components/TicketCard/TicketCard'
import { ticketsData } from '../../utils/mock/TicketsData'

export const Tickets = ({ handleLogout }) => {
    const [currentPageData, setCurrentPageData] = useState(0)

    useEffect(() => {
        let token = localStorage.getItem('auth_token')
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem('token_exp'))
            if (token_exp < Date.now() / 1000) {
                handleLogout()
            }
        }
    }, [handleLogout])

    return (
        <div className="page-container">
            <div className="row-view justify-between">
                <div>
                    <div className="size2 bold">Tickets</div>
                    <div className="size1 bold today">
                        <span>3 tickets</span>
                    </div>
                </div>
                <Link to={{ pathname: '/tickets/add' }} className="green-text">
                    <button className="primary-button row-view items-center">
                        <AiOutlinePlusCircle
                            color="white"
                            size={20}
                            className="mr10"
                        />
                        <div>Add Ticket</div>
                    </button>
                </Link>
            </div>

            <div className='row-view flex1 mt50'>
                <div className='column-view'>
                    {ticketsData.map((ticket, index) => {
                        return (
                            <TicketCard
                                key={index}
                                ticket={ticket}
                            />
                        )
                    })}
                </div>
            </div>

            <div className="row-view items-center justify-between mt50 ">
                <div className="row-view items-center">
                    <button className="secondary-button row-view p10 mr10">
                        <div className="mr5">25</div>
                        <PiCaretUpDownLight />
                    </button>
                    <div className="section-title">tickets per page</div>
                </div>
                <div className="pagination">
                    <div
                        className="pagination--item"
                        onClick={() =>
                            setCurrentPageData(
                                currentPageData === 0
                                    ? currentPageData
                                    : currentPageData - 1
                            )
                        }
                    >
                        <img src={arrowLeft} alt="dashboard" className="icon" />
                    </div>
                    <div
                        className="pagination--item"
                        onClick={() => setCurrentPageData(currentPageData + 1)}
                    >
                        <img
                            src={arrowRight}
                            alt="dashboard"
                            fill="red"
                            className="icon pagination--color"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
