import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

import dashboardIcon from "../../assets/icons/Dashboard.svg";
import usersIcon from "../../assets/icons/Users.svg";
import hotelsIcon from "../../assets/icons/Hotels.svg";
import staffIcon from "../../assets/icons/Contacts.svg";
import discussionsIcon from "../../assets/icons/Discussion.svg";
import ticketsIcon from "../../assets/icons/Tickets.svg";

export const Drawer = ({ pathname }) => {
    const [boOpen, setBoOpen] = useState(true);
    const [adminOpen, setAdminOpen] = useState(true);

    return (
        <div className='drawer-container'>
            <div className='row-view items-center cursor-pointer' onClick={() => setBoOpen(!boOpen)}>
                {boOpen ?
                    <BiChevronDown size={25} color='#428C78' />
                    :
                    <BiChevronUp size={25} color='#428C78' />
                }
                <div className='green-text bold'>
                    Back-Office
                </div>
            </div>
            <div className={`drawer-category ${boOpen ? 'open' : 'mb20'}`}>
                {boOpen &&
                    <div className='p20'>
                        <Link
                            to={{ pathname: '/dashboard' }}
                            className={`row-view items-centers mb20 drawer-link ${pathname.startsWith('/dashboard') && 'black-text'}`}
                        >
                            <img
                                src={dashboardIcon}
                                alt='dashboard'
                                className={`icon mr20 ${!pathname.startsWith('/dashboard') && 'opacity60'}`}
                            />
                            <div>
                                Dashboard
                            </div>
                        </Link>
                        <Link
                            to={{ pathname: '/hotels' }}
                            className={`row-view items-centers mb20 drawer-link ${pathname.startsWith('/hotels') && 'black-text'}`}
                        >
                            <img
                                src={hotelsIcon}
                                alt='hotels'
                                className={`icon mr20 ${!pathname.startsWith('/hotels') && 'opacity60'}`}
                            />
                            <div>
                                Hotels
                            </div>
                        </Link>
                        <Link
                            to={{ pathname: '/contacts' }}
                            className={`row-view items-centers mb20 drawer-link ${pathname.startsWith('/contacts') && 'black-text'}`}
                        >
                            <img
                                src={staffIcon}
                                alt='staff'
                                className={`icon mr20 ${!pathname.startsWith('/contacts') && 'opacity60'}`}
                            />
                            <div>
                                Contacts
                            </div>
                        </Link>
                        <Link
                            to={{ pathname: '/discussions' }}
                            className={`row-view items-centers mb20 drawer-link ${pathname.startsWith('/discussions') && 'black-text'}`}
                        >
                            <img
                                src={discussionsIcon}
                                alt='discussions'
                                className={`icon mr20 ${!pathname.startsWith('/discussions') && 'opacity60'}`}
                            />
                            <div>
                                Discussions
                            </div>
                        </Link>
                        <Link
                            to={{ pathname: '/tickets' }}
                            className={`row-view items-centers mb20 drawer-link ${pathname.startsWith('/tickets') && 'black-text'}`}
                        >
                            <img
                                src={ticketsIcon}
                                alt='tickets'
                                className={`icon mr20 ${!pathname.startsWith('/tickets') && 'opacity60'}`}
                            />
                            <div>
                                Tickets
                            </div>
                        </Link>
                    </div>
                }
            </div>

            <div className='row-view items-center cursor-pointer' onClick={() => setAdminOpen(!adminOpen)}>
                {adminOpen ?
                    <BiChevronDown size={25} color='#428C78' />
                    :
                    <BiChevronUp size={25} color='#428C78' />
                }
                <div className='green-text bold'>
                    Admin
                </div>
            </div>
            <div className={`drawer-category ${adminOpen ? 'open' : 'mb20'}`}>
                {adminOpen &&
                    <div className='p20'>
                        <Link
                            to={{ pathname: '/members' }}
                            className={`row-view items-centers mb20 drawer-link ${pathname.startsWith('/members') && 'black-text'}`}
                        >
                            <img
                                src={usersIcon}
                                alt='users'
                                className={`icon mr20 ${!pathname.startsWith('/members') && 'opacity60'}`}
                            />
                            <div>
                                Members
                            </div>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}