import React, { useEffect, useState } from 'react'
import { MdChevronLeft, MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { BiSolidChevronDown } from 'react-icons/bi'
import addImg from '../../assets/icons/AddImg.svg'

export const EditTicket = ({ handleLogout }) => {
    const navigate = useNavigate()
    const [severity, setSeverity] = useState([
        'High',
        'Medium',
        'Low'
    ])
    const [ticketOpen, setTicketOpen] = useState(false)

    const [ticket, setTicket] = useState({
        title: 'Revenue view',
        pictures: [null, null, null],
        severity: 'High',
        message: 'Revenue view missing information'
    })

    const handleSelect = (key, value) => {
        setTicket({ ...ticket, [key]: [value] })
    }

    const hiddenFileInput = React.useRef(null)
    const [photoInput, setPhotoInput] = useState(null)

    const handlePhoto = (key) => {
        hiddenFileInput.current.click()
        setPhotoInput(key)
    }

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0]
        if (fileUploaded) {
            const reader = new FileReader()

            reader.onload = (e) => {
                const img = new Image()
                img.src = e.target.result

                img.onload = () => {
                    switch (photoInput) {
                        case 'pictures':
                            if (img.width >= 150 && img.height >= 150) {
                                let inserted = false
                                let idx = 0
                                let arr = ticket.pictures
                                while (!inserted && idx < arr.length) {
                                    if (!arr[idx]) {
                                        arr[idx] = e.target.result
                                        inserted = true
                                    }
                                    idx++
                                }
                                setTicket({
                                    ...ticket,
                                    pictures: [...ticket.pictures],
                                })
                            } else {
                                alert(
                                    'Image size must be at least 1080x566 pixels '
                                )
                            }
                            break
                        default:
                            return 0
                    }
                }
            }

            reader.readAsDataURL(fileUploaded)
        }
    }

    useEffect(() => {
        let token = (localStorage.getItem('auth_token'));
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem('token_exp'));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }
        }
    }, [handleLogout]);

    return (
        <div className="page-container">
            <div className="row-view items-center">
                <MdChevronLeft size={50} onClick={() => navigate(-1)} />
                <div className="title-secondary">{ticket.title}</div>
            </div>
            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Title*</div>
                    <div className='row-view flex1 items-center'>
                        <input
                            className="input-text w300 ph20"
                            name="firstname"
                            placeholder="Ticket's title..."
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Severity</div>
                    <div className='row-view flex1 items-center'>
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer"
                                onClick={() => setTicketOpen(!ticketOpen)}
                            >
                                <div
                                    className={`p10 r8 
                                    ${ticket.severity.toString() === 'High' ?
                                            'red-text redbg01'
                                            :
                                            ticket.severity.toString() === 'Medium' ?
                                                'orange-text orangebg01'
                                                :
                                                ticket.severity.toString() === 'Low' &&
                                                'blue-text bluebg01'
                                        }
                                `}
                                >
                                    {ticket.severity !== ''
                                        ? ticket.severity
                                        : 'Select severity'}
                                </div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${ticketOpen && 'open'}`}>
                                {severity.length !== 0 ? (
                                    <>
                                        {severity.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect(
                                                            'severity',
                                                            item
                                                        )
                                                        setTicketOpen(false)
                                                    }}
                                                >
                                                    {item}
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">
                                            No ticket severity found
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Pictures (optional)</div>
                    <div className='row-view flex1 items-center'>
                        <div className="row-view items-center flex1">
                            <div className="row-view items-center">
                                {ticket.pictures.map((item, index) => {
                                    if (!item) {
                                        return (
                                            <div
                                                className="border-container b-dashed b2 r8 p20 mr10 cursor-pointer"
                                                onClick={() =>
                                                    handlePhoto('pictures')
                                                }
                                                key={index}
                                            >
                                                <img
                                                    src={addImg}
                                                    alt="addImg"
                                                />
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div
                                                className="p20 mr20 relative mr10"
                                                key={index}
                                            >
                                                <img
                                                    className="r8"
                                                    src={item}
                                                    alt="ticketPhoto"
                                                    style={{
                                                        width: '60px',
                                                        height: '60px',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                                <div
                                                    className="close-icon"
                                                    onClick={() => {
                                                        let arr = ticket.pictures
                                                        arr[index] = null
                                                        setTicket({
                                                            ...ticket,
                                                            pictures: arr,
                                                        })
                                                    }}
                                                >
                                                    <MdClose
                                                        size={20}
                                                        color="white"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                                <div className="section-subtitle mr20">
                                    (JPGs or PNGs - Maximum 10 MB)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Message*</div>
                    <div className='row-view flex1 items-center'>
                        <textarea
                            value={ticket.message}
                            rows={8}
                            className="input-text w300 ph20"
                            name="firstname"
                            placeholder="Write your comment..."
                        />
                    </div>
                </div>
            </div>
            <input
                type="file"
                accept="image/png, image/jpeg"
                ref={hiddenFileInput}
                onChange={(e) => handleInputPhoto(e)}
                style={{ display: 'none' }}
            />
        </div>
    )
}
