import { colors } from './HotelData';

export const newReservationOptions = {
    responsive: true,
    interaction: {
        mode: "index",
        intersect: false
    },
    stacked: false,
    plugins: {
        title: {
            display: false
        }
    },
    scales: {
        y: {
            display: true,
            beginAtZero: true,
            suggestedMax: 5,
            ticks: {
                callback: value => value !== 0 ? `${value} K` : `${value}`, // Add the "€" symbol to the tick label
            },
        },
        x: {
            skipNull: true, // Display data points even if there are missing labels
        },
    }
}

const newReservationLabels = ["Jul 1", "", "Jul 15", "", "Jul 30"]

export const newReservationData = {
    labels: newReservationLabels,
    datasets: [
        {
            label: "Evolution",
            data: [0.8, 1.2, 2.6, 2.6, 4.2],
            borderColor: `${colors[0]}80`,
            backgroundColor: colors[0],
            fill: false,
        }
    ]
}