import React, { useState, useEffect } from 'react'

import arrowLeft from '../../assets/icons/arrow-left.svg'
import arrowRight from '../../assets/icons/arrow-right.svg'
import { getContacts } from '../../utils/mock/ContactsData'
import { Table } from '../../components/Table/Table'
import { MdSearch } from 'react-icons/md'
import { PiCaretUpDownLight } from 'react-icons/pi'
import { TbFilterPlus } from 'react-icons/tb'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md'
import { BsPersonSquare } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { getAllManager } from '../../api/contact'
import { getAllOwner } from '../../api/contact'
import { getAllContacts } from '../../api/contact'

const cells = [
    'Name',
    'Availability',
    'Position',
    'Email',
    'Phone',
    'Role',
]

export const Contacts = ({ handleLogout }) => {
    const [contact, setContact] = useState([])
    const [currentPageData, setCurrentPageData] = useState(0)
    const [edit, setEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const formatDataManager = (data) => {
        let res = []
        data.map((manager) => {
            let emailValue = manager?.email;
            let phoneValue = manager?.phone;

            if (emailValue?.type === 'missing') {
                emailValue = null;
            }

            if (phoneValue?.type === 'missing') {
                phoneValue = null;
            }

            return res.push({
                id: manager?.sub,
                img: manager?.img || null,
                name: manager?.firstname + ' ' + manager.lastname.trim(),
                availability: manager?.available || false,
                position: 'Manager',
                email: emailValue || null,
                phone: phoneValue || null,
                role: manager?.roles[0]?.replace('hotel', '')

            })
        })
        return res
    }

    const formatDataOwner = (dataOwner) => {
        let res2 = []
        dataOwner.map((owner) => {
            let emailValue = owner?.email;
            let phoneValue = owner?.phone;

            if (emailValue?.type === 'missing') {
                emailValue = null;
            }

            if (phoneValue?.type === 'missing') {
                phoneValue = null;
            }

            return res2.push({
                id: owner?.sub,
                img: owner?.img || null,
                name: owner?.firstname + ' ' + owner.lastname.trim(),
                availability: owner?.available || false,
                position: 'Owner',
                email: emailValue || null,
                phone: phoneValue || null,
                role: owner?.roles[0]?.replace('hotel', '')
            })
        })
        return res2
    }

    const formatDataStaff = (dataStaff) => {
        let res2 = []
        dataStaff.map((staff) => {
            let emailValue = staff?.email;
            let phoneValue = staff?.phone;

            if (emailValue?.type === 'missing') {
                emailValue = null;
            }

            if (phoneValue?.type === 'missing') {
                phoneValue = null;
            }

            return res2.push({
                id: staff?.sub,
                img: staff?.img || null,
                name: staff?.firstname + ' ' + staff.lastname.trim(),
                availability: staff?.available || false,
                position: 'Staff',
                email: emailValue || null,
                phone: phoneValue || null,
                role: staff?.roles[0]?.replace('hotel', '')
            })
        })
        return res2
    }


    const getContacts = async () => {
        getAllContacts().then(contacts => {
            // make sort 

            let managers = formatDataManager(contacts.managers.content)
            let owners = formatDataOwner(contacts.owners.content)
            let staff = formatDataStaff(contacts.staff.content)

            setContact([...owners, ...managers, ...staff]);
            setIsLoading(false);
        });
    }

    const handleEdit = () => {
        if (edit) {
            // TODO API call to save updates
            setEdit(false);
        } else {
            setEdit(true);
        }
    }

    useEffect(() => {
        let token = localStorage.getItem('auth_token');
        if (token) {
            // Utilisez vos données en dur au lieu de l'appel API
            getContacts();

            const token_exp = JSON.parse(localStorage.getItem('token_exp'));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }
        }
    }, []);


    return (
        <>
            {isLoading ?
                <div className='row-view flex1 justify-center items-center'>
                    <div className='loader' />
                </div>
                :
                <div className="page-container">
                    <div className="row-view justify-between">
                        <div>
                            <div className="size2 bold">Contact</div>
                            <div className="size1 bold today">
                                <span>{contact.length} contacts</span>
                            </div>
                        </div>
                        <div className="row-view">
                            <div className="row-view justify">
                                <button
                                    className="secondary-button w160 borderless row-view items-center justify-center"
                                    onClick={() => handleEdit()}
                                >
                                    <div className="mr5">
                                        <BsPersonSquare />
                                    </div>
                                    <div>{edit ? 'Save' : 'Edit roles'}</div>
                                </button>
                                <Link
                                    className="primary-button none-decoration-link row-view items-center"
                                    to={{ pathname: '/contact/add' }}
                                >
                                    <AiOutlinePlusCircle
                                        color="white"
                                        size={20}
                                        className="mr10"
                                    />
                                    <div>Add Contact</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <button className="secondary-button row-view items-center p10 mt20">
                        <div
                            style={{
                                backgroundColor: 'gray',
                                width: 25,
                                height: 25,
                                borderRadius: 50,
                                marginRight: 5,
                            }}
                        />
                        <div className="mr5">The Mozart Hotel</div>
                        <MdArrowDropDown size={20} />
                    </button>

                    <div className="mt50 of-scroll">
                        <Table cells={cells} data={contact} type='contact' edit={edit} />
                    </div>

                    <div className="row-view items-center justify-between mt50 ">
                        <div className="row-view items-center">
                            <button className="secondary-button row-view p10 mr10">
                                <div className="mr5">25</div>
                                <PiCaretUpDownLight />
                            </button>
                            <div className="section-title">profiles per page</div>
                        </div>
                        <div className="pagination">
                            <div
                                className="pagination--item"
                                onClick={() =>
                                    setCurrentPageData(
                                        currentPageData === 0
                                            ? currentPageData
                                            : currentPageData - 1
                                    )
                                }
                            >
                                <img src={arrowLeft} alt="dashboard" className="icon" />
                            </div>
                            <div
                                className="pagination--item"
                                onClick={() => setCurrentPageData(currentPageData + 1)}
                            >
                                <img
                                    src={arrowRight}
                                    alt="dashboard"
                                    fill="red"
                                    className="icon pagination--color"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
