import React, { useEffect, useState } from 'react';

import addImg from '../../assets/icons/AddImg.svg';

import { MdChevronLeft, MdClose, MdCheck } from 'react-icons/md';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { BiSolidChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { getAllHotels, getHotelManagerList } from '../../api/hotel';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { HiDocument } from 'react-icons/hi'
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi'


const nationalities = [
    { name: 'France', icon: '🇫🇷' },
    { name: 'UK', icon: '🇬🇧' },
    { name: 'Italy', icon: '🇮🇹' },
    { name: 'US', icon: '🇺🇸' },
    { name: 'German', icon: '🇩🇪' },
    { name: 'Spanish', icon: '🇪🇸' }
]

const locations = [
    { city: 'Cannes', country: 'France' },
    { city: 'Paris', country: 'France' },
    { city: 'Prague', country: 'Czech' }
];

export const AddMember = ({ handleLogout }) => {
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);
    const [nationsOpen, setNationsOpen] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [locationOpen, setLocationsOpen] = useState(false);
    const [entityOpen, setEntityOpen] = useState(false);
    const [managersOpen, setManagersOpen] = useState(false);
    const [managers, setManagers] = useState([]);

    const [member, setMember] = useState({
        firstname: '',
        lastname: '',
        position: '',
        nationality: [],
        roles: [],
        available: false,
        entity: [],
        locations: [],
        manager: [],
        email: '',
        phone: '',
        access: false,
    })

    const [document, setDocument] = useState({
        name: '',
        date: '',
        size: '',
        type: '',
        file: ''
    });

    const hiddenFileInput = React.useRef(null);
    const [documentInput, setDocumentInput] = useState(null);

    const handlePhoto = () => {
        hiddenFileInput.current.click();
    };

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    if (img.width >= 150 && img.height >= 150) {
                        setMember({ ...member, photo: e.target.result });
                    } else {
                        alert('Image size must be at least 150x150 pixels ')
                    }
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleDocument = (key) => {
        hiddenFileInput.current.click();
        setDocumentInput(key);
    };

    const handleInputDocuments = (event) => {

        console.log(event.target.files[0])
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const documentData = e.target.result;

                switch (documentInput) {
                    case 'document':
                        setDocument({ ...document, name: event.target.files[0].name, date: event.target.files[0].lastModifiedDate.toDateString(), size: parseFloat(event.target.files[0].size / 1000000).toFixed(2), type: event.target.files[0].type, file: e.target.result });
                        break;
                    default:
                        return;
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleCheckbox = (value) => {
        if (member.roles.includes(value)) {
            setMember({ ...member, roles: member.roles.filter(el => el !== value) });
        } else {
            setMember({ ...member, roles: [...member.roles, value] });
        }
    }

    const handleInputText = (e) => {
        setMember({ ...member, [e.target.name]: e.target.value });
    }

    const handleSelect = (key, value) => {
        setMember({ ...member, [key]: [value] });
    }

    const handleSubmit = () => {
    }

    useEffect(() => {
        let token = (localStorage.getItem('auth_token'));
        if (token) {
            const token_exp = JSON.parse(localStorage.getItem('token_exp'));
            if (token_exp < Date.now() / 1000) {
                handleLogout();
            }
        }
    }, [handleLogout]);

    useEffect(() => {
        getAllHotels('', '', response => {
            if (response?.status === 'success') {
                setHotels(response?.content?.hotels);
            }
        })

        getHotelManagerList(response => {
            if (response?.status === 'success') {
                let m = response?.content
                let res = []
                m.map(el => {
                    el.manager.map(item => {
                        res.push(item)
                    })
                })
                setManagers(res)
            }
        })
    }, [])

    return (
        <form
            className={`page-container`}
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            {infoModal &&
                <div
                    className='filter-darker'
                    onClick={() => {
                        if (infoModal) {
                            setInfoModal(false);
                        }
                    }}
                />
            }
            <div className='row-view items-center'>
                <MdChevronLeft
                    size={50}
                    onClick={() => navigate(-1)}
                />
                <div className='title-secondary'>Add Contact</div>
            </div>

            <div className='subtitle mt50 ml50'>General information*</div>
            <div className='border-container r8 mt20'>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>First name*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='firstname' placeholder='First name...' value={member?.firstname} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Last name*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='lastname' placeholder='Last name...' value={member?.lastname} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Position*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='lastname' placeholder='Position...' value={member?.position} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Photo*</div>
                    <div className='row-view flex1 items-center'>
                        <div className='row-view items-center'>
                            {!member?.photo ?
                                <div
                                    className='border-container b-dashed b2 r8 p20 mr20'
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img
                                        src={addImg}
                                        alt='addImg'
                                    />
                                </div>
                                :
                                <div
                                    className='p20 mr20 relative'
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img
                                        className='r8'
                                        src={member?.photo}
                                        alt="staffPhoto"
                                        style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                    />
                                    <div
                                        className='close-icon'
                                        onClick={() => setMember({ ...member, photo: null })}
                                    >
                                        <MdClose size={20} color='white' />
                                    </div>
                                </div>
                            }
                            <div className='section-subtitle'>(JPGs or PNGs of at least 150x150px)</div>
                        </div>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputPhoto(e)}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Roles*</div>
                    <div className='row-view flex1'>
                        <div className='column-view justify-start'>
                            <div className='bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer' onClick={() => handleCheckbox('Owner')}>
                                <div className={`checkbox r4 mr10 ${member?.roles && member?.roles?.includes('Owner') && 'selected'}`}>
                                    {member?.roles?.includes('Owner') && <MdCheck color='white' />}
                                </div>
                                <div>Owner</div>
                            </div>
                            <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => handleCheckbox('Compliance')}>
                                <div className={`checkbox r4 mr10 ${member?.roles && member?.roles?.includes('Compliance') && 'selected'}`}>
                                    {member?.roles?.includes('Compliance') && <MdCheck color='white' />}
                                </div>
                                <div>Compliance</div>
                            </div>
                            <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => handleCheckbox('Manager')}>
                                <div className={`checkbox r4 mr10 ${member?.roles && member?.roles?.includes('Manager') && 'selected'}`}>
                                    {member?.roles?.includes('Manager') && <MdCheck color='white' />}
                                </div>
                                <div>Manager</div>
                            </div>
                        </div>
                        <div className='column-view justify-start ml10'>
                            <div className='bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer' onClick={() => handleCheckbox('Commercial')}>
                                <div className={`checkbox r4 mr10 ${member?.roles && member?.roles?.includes('Commercial') && 'selected'}`}>
                                    {member?.roles?.includes('Commercial') && <MdCheck color='white' />}
                                </div>
                                <div>Commercial</div>
                            </div>
                            <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => handleCheckbox('Support')}>
                                <div className={`checkbox r4 mr10 ${member?.roles && member?.roles?.includes('Support') && 'selected'}`}>
                                    {member?.roles?.includes('Support') && <MdCheck color='white' />}
                                </div>
                                <div>Support</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Availability*</div>
                    <div className='row-view flex1 items-center'>
                        <div className='row-view items-center w300'>
                            <div className={`toggle mr10 ${member?.available && 'checked'}`} onClick={() => setMember({ ...member, available: !member?.available })}>
                                <div className={`toggle-span ${member?.available && 'checked'}`} />
                            </div>
                            <div className='section-title mr10'>Available</div>
                            <div className='relative row-view items-center'>
                                <BsFillQuestionCircleFill color='#428C78' size={20} onClick={() => setInfoModal(!infoModal)} />
                                <div className={`info-modal ${infoModal && 'active'}`}>
                                    Is this hotelier unavailable or on vacation? Update its status to <span className='green-text bold'>"not available"</span> to notify every member. The hotelier will be <span className='green-text bold'>hidden in the application</span> during this period.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Entity linked*</div>
                    <div className='row-view flex1 items-center'>
                        <div className='relative'>
                            <div
                                className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20'
                                onClick={() => setEntityOpen(!entityOpen)}
                            >
                                <div>{member?.entity?.length ? `${member?.entity[0].name} - ${member?.entity[0].city}` : 'Select hotel'}</div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${entityOpen && 'open'}`}>
                                {hotels?.length !== 0 ?
                                    <>
                                        {hotels.map((hotel, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='select-item'
                                                    onClick={() => {
                                                        handleSelect('entity', hotel);
                                                        setEntityOpen(false);
                                                    }}
                                                >
                                                    <span className='section-title'>{hotel.name}</span> - {hotel.city}
                                                </div>
                                            )
                                        })}</>
                                    :
                                    <>
                                        <div
                                            className='select-item'
                                        >
                                            No hotels found
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Favourite locations</div>
                    <div className='row-view flex1 items-center'>
                        <div className='relative'>
                            <div
                                className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20'
                                onClick={() => setLocationsOpen(!locationOpen)}
                            >
                                <div>{member?.locations?.length !== 0 ? member?.locations[0].city + ' - ' + member?.locations[0].country : 'Select destinations'}</div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${locationOpen && 'open'}`}>
                                {locations?.length !== 0 ?
                                    <>
                                        {locations.map((location, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='select-item'
                                                    onClick={() => {
                                                        handleSelect('locations', location);
                                                        setLocationsOpen(false);
                                                    }}
                                                >
                                                    <span className='section-title'>{location.city}</span> - {location.country}
                                                </div>
                                            )
                                        })}</>
                                    :
                                    <>
                                        <div
                                            className='select-item'
                                        >
                                            No locations found
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Email address*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='email' placeholder='Email address...' value={member?.email} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Phone number*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='phone' placeholder='Phone number...' value={member?.phone} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Manager*</div>
                    <div className='row-view flex1 items-center'>
                        <div className='relative'>
                            <div
                                className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                onClick={() => setManagersOpen(!managersOpen)}
                            >
                                <div>{member?.manager?.length !== 0 ? `${member?.manager[0].firstname} ${member?.manager[0].lastname}` : 'Select Manager'}</div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${managersOpen && 'open'}`}>
                                {managers?.length !== 0 ?
                                    <>
                                        {managers.map((manager, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='select-item'
                                                    onClick={() => {
                                                        handleSelect('manager', manager);
                                                        setManagersOpen(false);
                                                    }}
                                                >
                                                    <span className='section-title'>{manager.firstname} {manager.lastname}</span>
                                                </div>
                                            )
                                        })}</>
                                    :
                                    <>
                                        <div
                                            className='select-item'
                                        >
                                            No managers found
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Contract*</div>

                    <div className='column-view flex1'>
                        <div className={`bgFAFAFA row-view items-center w80p p20 b r8 cursor-pointer`}>
                            <div className="row-column items-center justify-center w80p">
                                <div className="row-view justify-center section-subtitle mb10">
                                    PDF, JPG, PNG are allowed
                                </div>

                                <div
                                    className="border-container b-dashed b2 r8 p20 cursor-pointer mr10 bgLightGreen column-view justify-center items-center"
                                    onClick={() => handleDocument('document')}
                                >
                                    <AiOutlineCloudUpload size={60} color='#428C78' />
                                    <div className='green-text bold'>Browse to choose a file</div>
                                </div>
                                {document?.file ?
                                    (
                                        <div className='row-view justify-between border-container w100 pv12 r8 mb20 mt20'>
                                            <div className='row-view'>
                                                <HiDocument className='ml10' size={50} color='#428C78' />
                                                <div className='column-view justify-center '>
                                                    <div className='bold'>{document.name}</div>
                                                    <div className='color-grey mt05'>{document.date + ' - ' + document.size} Mo</div>
                                                </div>
                                            </div>
                                            <div className='cursor-pointer'>
                                                <PiDotsThreeOutlineVerticalFill size={50} color='#428C78' />
                                            </div>
                                        </div>
                                    ) : ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row-view justify-end mt20'>
                <button className='secondary-button mr20' type='reset' onClick={() => navigate(-1)}>Cancel</button>
                <button className='primary-button' type='submit'>Create Contact</button>
            </div>
            <input
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                ref={hiddenFileInput}
                onChange={(e) => handleInputDocuments(e)}
                style={{ display: 'none' }}
            />
        </form>
    )
}