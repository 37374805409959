import React, { useEffect, useState } from 'react';

import addImg from '../../assets/icons/AddImg.svg';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { TbFilterPlus } from 'react-icons/tb';
import { MdClose } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateHotelContent } from '../../api/hotel';
import { MdSearch } from 'react-icons/md';
import { DocumentCard } from './DocumentCard';
import { HiDocument } from 'react-icons/hi'
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi'

export const HotelDocuments = ({ handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [hotelSub, setHotelSub] = useState(location.pathname.replace('/hotels/profile/', ''));

    const [document, setDocument] = useState({
        name: '',
        date: '',
        size: '',
        type: '',
        file: ''
    });

    const [hotel, setHotel] = useState({
        name: '',
        showcase: {
            logo: null,
            banner: null,
        },
        address: '',
        zipcode: '',
        city: '',
        phone: '',
        presentation: '',
        overview: [null, null, null, null, null, null],
        year_of_creation: '',
        year_of_renovation: '',
        nb_rooms: '',
        labels: [],
        food_health: [],
        requirements: [],
        music_list: [],
        communities: []
    })

    const hiddenFileInput = React.useRef(null);
    const [documentInput, setDocumentInput] = useState(null);

    const handleDocument = (key) => {
        hiddenFileInput.current.click();
        setDocumentInput(key);
    };

    const handleInputDocuments = (event) => {

        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const documentData = e.target.result;

                switch (documentInput) {
                    case 'document':
                        setDocument({ ...document, name: event.target.files[0].name, date: event.target.files[0].lastModifiedDate.toDateString(), size: parseFloat(event.target.files[0].size / 1000000).toFixed(2), type: event.target.files[0].type, file: e.target.result });
                        break;
                    default:
                        return;
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleSubmit = () => {
        updateHotelContent(hotel, hotelSub, ok => {
            if (ok) {
                navigate(-1);
            }
        })
    }
    return (
        <form
            className='page-container'
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <div className='row-view items-center justify-center'>
                <div className='border-container p20 r8 column-view items-center justify-center w80p'>
                    <div className="subtitle mb10">Upload your documents</div>
                    <div className="row-column items-center justify-center w80p">
                        <div className="row-view justify-center section-subtitle mb10">
                            PDF, JPG, PNG are allowed
                        </div>

                        <div
                            className="border-container b-dashed b2 r8 p20 cursor-pointer mr10 bgLightGreen column-view justify-center items-center"
                            onClick={() => handleDocument('document')}
                        >
                            <AiOutlineCloudUpload size={60} color='#428C78' />
                            <div className='green-text bold'>Browse to choose a file</div>
                        </div>
                        {document?.file ?
                            (
                                <div className='row-view justify-between border-container w100 pv12 r8 mb20 mt20'>
                                    <div className='row-view'>
                                        <HiDocument className='ml10' size={50} color='#428C78' />
                                        <div className='column-view justify-center '>
                                            <div className='bold'>{document.name}</div>
                                            <div className='color-grey mt05'>{document.date + ' - ' + document.size} Mo</div>
                                        </div>
                                    </div>
                                    <div className='cursor-pointer'>
                                        <PiDotsThreeOutlineVerticalFill size={50} color='#428C78' />
                                    </div>
                                </div>
                            ) : ''
                        }

                    </div>
                </div>

            </div>
            <div className='mt20'>
                <div className='row-view justify-between mb20'>
                    <div className='subtitle black-text'>Documents</div>
                    <div className='row-view'>
                        <button className='secondary-button borderless row-view items-center'>
                            <div className='mr5'><TbFilterPlus /></div>
                            <div>
                                Filter
                            </div>
                        </button>
                        <div className='secondary-search-bar-container row-view items-center justify-center'>
                            <div className='mr10'><MdSearch size={20} /></div>
                            <input type='text' placeholder='Search reservations' className='secondary-search-bar' />
                        </div>
                    </div>
                </div>
                <div>
                    <DocumentCard />
                    <DocumentCard />
                </div>
            </div>
            <input
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                ref={hiddenFileInput}
                onChange={(e) => handleInputDocuments(e)}
                style={{ display: 'none' }}
            />
        </form >
    )
}